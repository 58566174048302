@keyframes spin {
  0% {
    transform: rotate(1turn);
  }
  100% {
    transform: rotate(0deg);
  }
}

.animation-rotate-progress {
  animation: spin 3s linear infinite;
}
