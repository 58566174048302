@charset "utf-8";

/*header
----------------------------------*/
.header {
  height: 65px;
  color: #fff;
  font-weight: bold;
  background-color: $c-navy;
  position: relative;
  z-index: 1000;

  .header-sub {
    height: 20px;
    background-color: $c-lightgray;
    color: $c-text;

    .inner {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .customer-information {
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }

    .progress {
      display: flex;
      align-items: center;
      gap: 0 10px;

      .btn-reload {
        width: 20px;
        height: 20px;
        cursor: pointer;
      }
    }
  }

  .header-left {
    float: left;
  }
  .header-right {
    float: right;
    line-height: 45px;
    display: flex;
    flex-direction: row-reverse;

    .icon-list {
      background: url(../img/icon_list.svg) no-repeat center;
      background-size: 10%;
    }
    .constraction-name-shorten {
      display: inline-block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-text-overflow: ellipsis;
      -o-text-overflow: ellipsis;
    }
  }

  .global-nav {
    li {
      float: left;
      height: 45px;
      text-align: center;
      a {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        color: #fff;
        &:hover {
          background-color: $c-blue;
        }
      }
      &.on {
        background-color: $c-blue;
      }
      &.nav00 {
        width: 65px;
        &.disabled a:hover {
          background-color: inherit;
          cursor: initial;
        }
        &.dropdown::after {
          content: none;
        }
        a {
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: 30px;
            height: 30px;
          }
        }
        .dropdown-menu {
          width: 180px;
          background-color: #45505f;
          color: #1c61b2;
          li {
            height: 45px;
            opacity: 0.5;
            pointer-events: none;
            a {
              justify-content: space-between;
              padding: 0 20px;
            }
            &.active {
              opacity: 1;
              pointer-events: auto;
            }
          }
        }
      }
      &.nav01 {
        width: 60px;
      }
      &.nav02 {
        width: 90px;
      }
      &.nav03 {
        width: 100px;
      }
      &.nav04 {
        width: 80px;
      }
      &.nav05 {
        width: 80px;
      }
      &.nav06 {
        width: 75px;
      }
      &.nav07 {
        width: 80px;
      }
      &.nav08 {
        width: 100px;
      }
      &.nav09 {
        width: 75px;
      }
      &.is-disabled {
        background-color: #2f3844;
        cursor: default;
        pointer-events: none;

        > a {
          color: #6d6d6d;
        }
      }
    }
  }

  .dropdown {
    position: relative;
    text-indent: -10px;
    &:after {
      position: absolute;
      content: "";
      top: 50%;
      right: 6px;
      width: 0;
      height: 0;
      margin-top: -3px;
      border-style: solid;
      border-width: 5px 4px 0 4px;
      border-color: #ffffff transparent transparent transparent;
    }
  }
  .dropdown-menu {
    position: absolute;
    z-index: 1002;
    width: 100%;
    text-indent: 0;
    @include font-size(1.2);
    background-color: #2f3844;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2), 0 1px 2px rgba(0, 0, 0, 0.2);
    li {
      float: none;
      height: auto;
      line-height: 1;
      text-align: left;
      .category-ttl {
        display: block;
        padding: 10px 5px 5px 12px;
        color: rgba(255, 255, 255, 0.7);
      }
      a {
        display: block;
        width: 100%;
        height: 100%;
        padding: 10px 5px 10px 12px;
        color: #fff;
        &:hover {
          background-color: $c-blue;
        }
      }
      li {
        a {
          padding-left: 25px;
        }
      }
    }
  }

  .user-nav {
    width: 50px;
    height: 45px;
    text-align: center;
    .icon-menu {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 45px;
      > img {
        width: 25px;
        height: 25px;
      }
      &:hover {
        cursor: pointer;
        background-color: $c-blue;
      }
    }

    .dropdown-menu {
      right: 0;
      width: 170px;
      background-color: #45505f;

      .user-name {
        padding: 10px 0px 10px 12px;
        border-bottom: 1px solid #7e8590;
        margin: 0 4px;
      }

      li a {
        padding: 10px 5px 10px 16px;
      }
    }
  }

  .data-export {
    margin-right: 15px;
    // float: right;
    transition: all 0.15s ease-in-out;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 45px;

    &:hover {
      cursor: pointer;
      background-color: $c-blue;
    }

    img {
      width: 25px;
      height: 25px;
    }

    > .label-badge {
      background: #cc0000;
      color: white;
      font-size: 8px;
      position: absolute;
      top: 5px;
      right: 4px;
      border-radius: 50%;
      border: 1px solid #ffc79e;
      height: 20px;
      width: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .notification-menu {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 45px;

    &:hover {
      cursor: pointer;
      background-color: $c-blue;
    }

    img {
      width: 25px;
      height: 25px;
    }
  }

  .header-weather {
    position: relative;

    .header-weather-inner {
      height: 45px;
      display: flex;
    }

    .header-weather__icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 45px;

      .sunny {
        width: 30px;
        height: 30px;
      }
      .cloud {
        width: 31px;
        height: 18px;
      }
      .rain {
        width: 23px;
        height: 28px;
      }
    }

    .header-weather__wind {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 3px;

      .header-weather__wind-direction {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .header-weather__wind-label {
        font-size: 0.8rem;
        line-height: 1em;
      }
    }

    .header-weather-popup {
      background: #fff;
      color: #111;
      padding: 0 15px 15px;
      position: absolute;
      top: 50px;
      right: 0px;
      width: max-content;
      border-radius: 2px;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    }

    .header-weather-table {
      margin-top: -10px;
      display: grid;
      place-content: center;
      grid-template-columns: repeat(6, auto);
      border-top: 1px solid #adb5bd;
      border-left: 1px solid #adb5bd;

      tr,
      tbody,
      thead,
      tfoot {
        display: contents;
      }
      th,
      td {
        line-height: normal;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        border-bottom: 1px solid #adb5bd;
        border-right: 1px solid #adb5bd;
        padding: 4px;
      }
      thead {
        th {
          background-color: #eeeeee;
        }
      }
      tbody {
        th {
          background-color: #f8f8f8;
        }
      }

      .header-weather-table__weather,
      .header-weather-table__wind {
        padding: 5px;
      }
      .header-weather-table__weather-icon,
      .header-weather-table__wind-icon {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .header-weather-table__weather-icon {
        min-height: 30px;
        .sunny {
          width: 30px;
          height: 30px;
        }
        .cloud {
          width: 31px;
          height: 18px;
        }
        .rain {
          width: 23px;
          height: 28px;
        }
      }
      .header-weather-table__weather-label,
      .header-wind-table__wind-label {
        font-size: 1rem;
        margin-top: 5px;
      }
    }

    .arrow {
      width: 12px;
      height: 21px;

      &.north {
        // 北
        transform: rotate(0deg);
      }
      &.north-northeast {
        // 北北東
        transform: rotate(22.5deg);
      }
      &.northeast {
        // 北東
        transform: rotate(45deg);
      }
      &.east-northeast {
        // 東北東
        transform: rotate(67.5deg);
      }
      &.east {
        transform: rotate(90deg);
      }
      &.east-southeast {
        // 東南東
        transform: rotate(112.5deg);
      }
      &.southeast {
        // 南東
        transform: rotate(135deg);
      }
      &.south-southeast {
        // 南南東
        transform: rotate(157.5deg);
      }
      &.south {
        // 南
        transform: rotate(180deg);
      }
      &.south-southwest {
        // 南南西
        transform: rotate(202.5deg);
      }
      &.southwest {
        // 南西
        transform: rotate(225deg);
      }
      &.west-southwest {
        // 西南西
        transform: rotate(247.5deg);
      }
      &.west {
        // 西
        transform: rotate(270deg);
      }
      &.west-northwest {
        // 西北西
        transform: rotate(292.5deg);
      }
      &.northwest {
        // 北西
        transform: rotate(315deg);
      }
      &.north-northwest {
        // 北北西
        transform: rotate(337.5deg);
      }
    }
  }

  #notifications-icon {
    padding: 0 4px;
    transition: all 0.15s ease-in-out;
    position: relative;
    font-size: 1.8rem;

    &:hover {
      cursor: pointer;
      background-color: #2f3844;
    }
    img {
      width: 30px;
      height: 45px;
    }
  }

  .badge:after {
    content: "";
    position: absolute;
    background: red;
    height: 8px;
    width: 8px;
    top: 10px;
    right: 4px;
    text-align: center;
    border-radius: 50%;
  }

  .modal-dialog {
    color: #111;
    line-height: initial;
  }

  .modal-ttl {
    line-height: normal;
  }

  .modal-body {
    font-weight: normal;
  }

  .data-export-area {
    z-index: 1001;
    position: fixed;
    top: 65px;
    right: 0;
    bottom: 0;
    width: 350px;
    transition: transform 0.2s linear;
    transform: translateX(100%);
    background-color: rgba(86, 86, 86, 0.7);
    background-color: #ffffff;
    color: #000000;
    overflow-y: auto;
    line-height: 45px;

    .btn {
      line-height: normal;
    }

    .clickable {
      &:hover {
        cursor: pointer;
      }
    }
    .inline-block {
      display: inline-block;
    }
    &.open {
      transform: translateX(0);
      box-shadow: -2px 0 10px 0 rgba(4, 0, 0, 0.23);
    }
    .sticky-header {
      position: -webkit-sticky;
      position: sticky;
      z-index: 100;
      @supports (-ms-ime-align: auto) {
        position: static;
      }
      top: 0;
    }
    .title {
      text-align: center;
      background-color: #ccc;
      position: relative;

      .title-text {
        margin-right: 5px;
      }
      .reload-icon {
        width: 23px;
        vertical-align: middle;
      }
      .close-area {
        position: absolute;
        top: 0;
        right: 10px;
        height: 100%;
        display: flex;
        align-items: center;
        .close-icon {
          width: 15px;
        }
      }
    }
    .list-header {
      height: 41px;
      display: flex;
      flex-direction: row-reverse;
      background: #fff;
      padding: 8px 10px;
      align-items: center;
      border-bottom: 1px solid #999;
      .read-all-btn {
        line-height: 1;
      }
    }
    .btn-list {
      line-height: 1.4rem;
      padding: 4px 7.5px;
      float: right;
    }
    .list {
      position: relative;
      padding: 10px 15px 10px 10px;
      border-bottom: solid 1px #cccccc;

      &:hover {
        cursor: pointer;
      }
      &.new {
        background: #fffad8;
      }
      &.read {
        background: #eff0f3;
        &:hover {
          cursor: default;
        }
      }

      .btn-none {
        display: none;
      }
      dl.data-content {
        line-height: 1.4;
        padding-bottom: 5px;
        dl {
          display: flex;
          dt {
            width: 13rem;
            text-align: right;
          }
        }
      }

      .data-header {
        display: flex;
        justify-content: space-between;

        .subject {
          font-size: 1.2em;
          margin-left: 5px;
          line-height: 2;
        }
        .accepted-date {
          font-size: 13px;
          line-height: 2;
        }
      }
      .img-area {
        width: 50px;
        text-align: center;
        margin: 0 5px 0 0;
        .icon {
          width: 20px;
          height: auto;
          margin: -2px 5px 0 0;
        }
        .img {
          width: 100%;
          height: auto;
          vertical-align: middle;
        }
      }
    }
  }

  .animation-rotate-progress:hover {
    background-color: transparent;
  }

  .construction-name {
    display: flex;
    justify-content: flex-end;
    position: relative;
    float: right;
    padding: 0 10px 0 0;
    transition: all 0.15s ease-in-out;
    width: 360px;
    &:hover {
      background-color: #2f3844;
      cursor: pointer;
    }
    .icon-mode_edit {
      padding: 0 8px;
      font-size: 22px;
      visibility: hidden;
      &:hover {
        cursor: pointer;
      }
    }
    &:hover .icon-mode_edit {
      visibility: visible;
    }
    .icon-cached {
      padding-right: 0;
      font-size: 22px;
      &:hover {
        cursor: pointer;
      }
    }
    .construction-detail {
      position: absolute;
      z-index: 1002;
      top: 45px;
      left: 0;
      width: 100%;
      padding: 5px 3%;
      line-height: 1.4;
      font-weight: normal;
      @include font-size(1.2);
      color: $c-text;
      border-radius: 3px;
      border: 2px solid $c-blue;
      background-color: #f6fcff;
      box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2), 0 1px 2px rgba(0, 0, 0, 0.2);
      cursor: default;
      dt {
        float: left;
        width: 35%;
        margin-bottom: 5px;
        text-align: right;
      }
      dd {
        margin-left: 37%;
        margin-bottom: 5px;
      }
    }
  }
}

/* IE11 Hack (for drawer stacking context) */
@media (-ms-high-contrast: none) {
  *::-ms-backdrop,
  .header .data-export-area {
    overflow-y: scroll;
  }
  .header #notification {
    overflow-y: scroll;
  }
}
