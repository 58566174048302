.cursor {
  &-0 * {
    cursor: url(../img/cursor/0.cur), default;
  }
  &-1 * {
    cursor: url(../img/cursor/1.cur), default;
  }
  &-2 * {
    cursor: url(../img/cursor/2.cur), default;
  }
  &-3 * {
    cursor: url(../img/cursor/3.cur), default;
  }
  &-4 * {
    cursor: url(../img/cursor/4.cur), default;
  }
  &-5 * {
    cursor: url(../img/cursor/5.cur), default;
  }
  &-6 * {
    cursor: url(../img/cursor/6.cur), default;
  }
  &-7 * {
    cursor: url(../img/cursor/7.cur), default;
  }
  &-8 * {
    cursor: url(../img/cursor/8.cur), default;
  }
  &-9 * {
    cursor: url(../img/cursor/9.cur), default;
  }
  &-10 * {
    cursor: url(../img/cursor/10.cur), default;
  }
  &-11 * {
    cursor: url(../img/cursor/11.cur), default;
  }
  &-12 * {
    cursor: url(../img/cursor/12.cur), default;
  }
}

.cursor-default {
  cursor: default;
}

.schedule-cursor {
  &-0 {
    cursor: url(../img/cursor/0.cur), default;
  }
  &-5 {
    cursor: url(../img/cursor/5.cur), default;
  }
  &-7 {
    cursor: url(../img/cursor/7.cur), default;
  }
  &-8 {
    cursor: url(../img/cursor/8.cur), default;
  }
  &-nix {
    cursor: url(../img/cursor/nix.cur), default;
  }
}
