@charset "utf-8";

/*matrix
----------------------------------*/
.modal-matrix-help {
  .help-ttl {
    margin-bottom: 5px;
    color: #2b333e;
    font-weight: bold;
    @include font-size(1.5);
    .icon {
      position: relative;
      top: -2px;
      margin-right: 5px;
      @include font-size(2.2);
    }
  }
  .help-tbl {
    width: 100%;
    border: 1px solid #ccc;
    th {
      text-align: center;
      background-color: #fff;
      border-right: 1px solid #e4e4e4;
      border-bottom: 1px solid #e4e4e4;
    }
    td {
      padding: 5px 10px;
      background-color: #fff;
      border-bottom: 1px solid #e4e4e4;
    }
    .icon {
      @include font-size(2.4);
    }
  }
  .mark-tbl {
    th {
      width: 60px;
      padding: 5px;
    }
    td {
      padding: 8px 10px;
    }
  }
  .bgcolor-tbl {
    th {
      width: 50px;
      height: 43px;
      padding: 0;
      &.th-blue {
        background-color: #e2f2fe;
      }
      &.th-yellow {
        background-color: #feff53;
      }
      &.th-orange {
        background-color: #ffa11e;
      }
      &.th-green {
        background-color: #e1fae1;
      }
      &.th-error .cell-wrap {
        position: relative;
        height: 100%;
      }
      &.th-error .cell-wrap:after {
        content: " ";
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
        border: 2px solid #ff0000;
      }
    }
    td {
      @include font-size(1.2);
    }
  }
  .date-tbl {
    th {
      padding: 5px;
    }
    .plan {
      color: #888;
    }
  }
  .icon-tbl {
    th {
      width: 60px;
      height: 50px;
    }
  }
}

/*status-list
----------------------------------*/
.status-list {
  overflow: hidden;
  li {
    float: left;
    &:first-child {
      button {
        border-radius: 4px 0 0 4px;
      }
    }
    &:last-child {
      button {
        border-radius: 0 4px 4px 0;
        border-right: solid 1px #ccc;
      }
    }
  }
  button {
    width: 50px;
    height: 28px;
    border: solid 1px #ccc;
    border-right: none;
    background-color: #fff;
    transition: all 0.15s ease-in-out;
    &:hover {
      cursor: pointer;
      background-color: #e1e1e1;
    }
    &.on {
      color: #fff;
      background-color: $c-btn-lightblue;
    }
    &:disabled {
      cursor: default;
      color: #8f8f8f;
      background-color: #f1f1f1;
    }
  }
}

.modal-body-top {
  .status-group {
    .form-txt {
      vertical-align: middle;
      .status-txt {
        word-wrap: break-word;
        max-width: 115px;
        display: inline-block;
      }
    }
    .status-list {
      display: inline-block;
      vertical-align: middle;
    }
    span a {
      text-decoration: underline;
    }
  }
}

/* matrix-task-editor
----------------------------------*/
.matrix-task-editor {
  .react-datepicker__input-container {
    width: 139px;
  }
}

.schedule-datepicker {
  display: inline-block;
  position: relative;
}

/*tooltip
----------------------------------*/
.linkage-tooltip {
  font-family: inherit !important;
  position: absolute;
  bottom: 45px;
  right: 25px;
  .tooltip-inner {
    padding: 10px !important;
    max-width: none !important;
    color: $c-text !important;
    background-color: #fff2cc !important;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2), 0 1px 2px rgba(0, 0, 0, 0.2);
    .form-row:last-child {
      margin-bottom: 0;
    }
    .wrap {
      white-space: pre-wrap;
    }
  }
}

.matrix-cell-date {
  transform-origin: left;
  width: calc(80px * 1.1);
  text-align: left;
}
