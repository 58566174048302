/* VARS */
$darkNavy: #213140;

/* MIXINS */
@mixin hideInput {
  width: 0;
  height: 0;
  position: absolute;
  left: -9999px;
}

/* TOGGLE STYLING */
.layout-toggle-button-with-words {
  box-sizing: border-box;
  font-size: 0;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: stretch;
  input {
    @include hideInput;
  }
  input + label {
    margin: 0;
    padding: 0.75rem 2rem;
    box-sizing: border-box;
    position: relative;
    display: inline-block;
    border: solid 1px #ddd;
    font-size: 1rem;
    line-height: 140%;
    font-weight: 600;
    text-align: center;
    box-shadow: 0 0 0 rgba(255, 255, 255, 0);
    transition: border-color 0.15s ease-out, color 0.25s ease-out, background-color 0.15s ease-out,
      box-shadow 0.15s ease-out;

    /* ADD THESE PROPERTIES TO SWITCH FROM AUTO WIDTH TO FULL WIDTH */
    /*flex: 0 0 50%; display: flex; justify-content: center; align-items: center;*/
    /* ----- */

    &:first-of-type {
      border-radius: 6px 0 0 6px;
      border-right: none;
    }
    &:last-of-type {
      border-radius: 0 6px 6px 0;
      border-left: none;
    }
  }
  input:hover + label {
    border-color: $darkNavy;
  }
}
