@charset "utf-8";

/*login
----------------------------------*/
.login-area {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);

  .login-box {
    width: 380px;
    margin: 0 auto;
    padding: 50px 30px 10px 30px;
    background-color: #fff;
    .logo {
      text-align: center;
      margin-bottom: 15px;
      color: $c-text-navy;
      strong {
        display: block;
        font-weight: bold;
        @include font-size(3);
        span {
          color: $c-text-navy;
        }
        .sx-progress {
          color: $c-blue;
        }
        .layout {
          color: $c-orange;
        }
      }
    }
    .form-group {
      float: none;
      margin-bottom: 15px;
    }
    .form-control {
      width: 100%;
    }
    .btn-area {
      text-align: center;
      margin-bottom: 20px;
    }
    .btn {
      width: 100%;
      height: 40px;
    }

    .login-error-message {
      color: $c-text-error;
      margin-top: 0.5rem;
      text-align: left;
    }

    .btn-clear-storage {
      text-align: right;
      margin-right: -17px;

      > button {
        background: none;
        border: none;
        color: #aaa;
        cursor: pointer;
        font-size: 90%;
        letter-spacing: 1px;
        padding: 7px 0 0;
      }
    }
  }
}

.recommended-env {
  width: 380px;
  margin-top: 10px;
  padding: 10px 15px;
  background-color: #fff;
  color: #aaa;

  dt {
    float: left;
    text-align: right;
    clear: left;
    margin-right: 15px;
    margin-bottom: 6px;
    width: 60px;
    &.subject {
      white-space: nowrap;
    }
  }
  dd {
    float: left;
  }
}
