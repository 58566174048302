@charset "utf-8";

html {
  font-family: "游ゴシック", "Yu Gothic", "メイリオ", Meiryo, "ヒラギノ角ゴ Pro", Hiragino Kaku Gothic Pro, sans-serif;
  font-size: 62.5%;
  font-weight: bold;
  line-height: 1.4;
  color: $c-text;
  word-wrap: break-word;
  box-sizing: border-box;
}

body {
  width: 100%;
  min-width: 1240px;
  @include font-size(1.3);
  background-color: #eff0f3;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body,
#app,
.wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.clearfix:after {
  content: "";
  display: block;
  clear: both;
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: bottom;
  border: none;
}

a {
  color: $c-blue;
  outline: none;
  text-decoration: none;
  &:link {
    text-decoration: none;
    color: $c-blue;
    transition: all 0.15s ease-in-out;
  }
  &:visited {
    text-decoration: none;
    color: $c-blue;
  }
  &:hover {
    cursor: pointer;
    text-decoration: none;
    color: #0753a1;
  }
  &[disabled] {
    color: #ccc;
    pointer-events: none;
  }
  &.underline {
    color: inherit;
    text-decoration: underline;
  }
}

/*pagination
----------------------------------*/
.pagination {
  width: 100%;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0;
  padding: 6px;
  color: #fff;
  background-color: #252c36;
  height: 30px;
  z-index: 101;
  .null-box {
    width: 100%;
    margin-top: auto;
    margin-bottom: auto;
    &.grid1 {
      -ms-grid-column: 1;
    }
    &.grid3 {
      -ms-grid-column: 3;
    }
  }
  .left-arrow-box {
    width: 50px;
    text-align: center;
    border-bottom: 1px solid #fff;
    margin-right: 0;
    margin-left: auto;
    cursor: pointer;
  }

  .right-arrow-box {
    width: 50px;
    text-align: center;
    border-bottom: 1px solid #fff;
    margin-right: auto;
    margin-left: 0;
    cursor: pointer;
  }
  .page-status {
    text-align: center;
    margin: auto 60px;
    display: inline-block;
    -ms-grid-column: 2;
    white-space: nowrap;
  }
  .limit {
    position: absolute;
    right: 20px;
    top: 7px;
  }
  input[type="text"] {
    color: $c-text;
    text-align: center;
  }
}

/*input,button,select,textarea
----------------------------------*/
*:focus {
  outline: none !important;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
  &.form-control {
    vertical-align: top;
  }
}

/*form
----------------------------------*/
.form-control {
  display: block;
  width: 100%;
  padding: 0.45rem 0.75rem;
  line-height: 1.25;
  @include font-size(1.3);
  background-color: #fff;
  background-image: none;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}
.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.form-control:focus {
  background-color: #fff;
  border-color: #55a6f8;
  outline: none;
}
.form-control::placeholder {
  color: #868e96;
  opacity: 1;
}
.form-control:disabled {
  background-color: #e9ecef;
  opacity: 1;
}
.form-left {
  float: left;
  width: 1000px;
}
.form-row {
  margin-bottom: 15px;
  &:after {
    content: "";
    display: block;
    clear: both;
  }
}

.form-row-flex {
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
  .form-group-flex {
    text-align: right;
    .form-label {
      display: inline-block;
      margin-right: 8px;
      &.chiba {
        float: left;
      }
    }
    .form-control {
      display: inline-block;
    }
  }
}

.form-group {
  float: left;
  text-align: right;
  &.ta-u {
    text-align: unset;
  }
  .form-label {
    display: inline-block;
    margin-right: 8px;
    &.chiba {
      float: left;
    }
  }
  .form-label-left {
    display: inline-block;
    margin-right: 8px;
    float: left;
  }
  .form-control {
    display: inline-block;
  }
  .form-txt {
    display: inline-block;
    text-align: left;
    vertical-align: top;
  }
  .icon-date_range {
    margin-left: 3px;
    color: #959595;
    @include font-size(2.6);
    &:hover {
      cursor: pointer;
      color: #757575;
    }
  }
  .icon-add_circle {
    @include font-size(2.5);
    color: $c-blue;
    &[disabled],
    &[disabled]:hover {
      color: $c-btn-gray;
      cursor: default;
    }
    &:hover {
      cursor: pointer;
      color: #0753a1;
    }
  }
  .icon-cancel {
    @include font-size(2);
    color: $c-blue;
    &:hover {
      cursor: pointer;
      color: #0753a1;
    }
  }
  .icon-help {
    @include font-size(2.6);
    color: #313a47;
    &:hover {
      cursor: pointer;
      color: #455265;
    }
  }
  .note {
    margin-left: 8px;
    @include font-size(1.2);
  }
}

.form-error {
  clear: both;
  text-align: right;
}

.form-error-message {
  color: $c-text-error;
  display: inline-block;
  margin-top: 0.5em;
  text-align: left;
  &.othername {
    margin-top: 0;
  }
  &.schedule-othername {
    margin: 0 0 5px 80px;
    width: 190px;
  }
}

.file-box {
  max-height: 300px;
  margin-top: -8px;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}

.file-upload-box {
  max-height: 300px;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}

.form-file-list {
  li {
    position: relative;
    display: table;
    table-layout: fixed;
    width: 100%;
    min-height: 41px;
    padding: 6px 8px;
    border: 1px solid #ccc;
    border-top: none;
    border-bottom: 1px solid #e4e4e4;
    background-color: #fff;
    &:last-child {
      border-bottom: none;
    }
  }
  a {
    text-decoration: underline;
  }
  span {
    display: table-cell;
    vertical-align: middle;
  }
  .img-area {
    width: 35px;
    margin-right: 10px;
    text-align: center;
    .icon {
      width: 28px;
      height: auto;
    }
    .img {
      width: 100%;
      height: auto;
      vertical-align: middle;
    }
  }
  .file-name {
    padding-left: 10px;
  }
  .icon-delete {
    width: 20px;
    color: #858585;
    @include font-size(2);
    &:hover {
      cursor: pointer;
      color: #656565;
    }
  }
}

.comment-upload-box {
  max-height: 300px;
  margin-top: -8px;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}

.form-comment-list {
  li {
    padding: 6px 8px;
    border: 1px solid #ccc;
    border-top: none;
    border-bottom: 1px solid #e4e4e4;
    background-color: #fff;
    &:last-child {
      border-bottom: none;
    }
  }
  .comment-info {
    margin-top: 5px;
    color: #777;
    @include font-size(1.2);
    .user {
      padding-left: 10px;
    }
    .acttion {
      float: right;
      a {
        text-decoration: underline;
      }
    }
  }
}

.form-tbl-add {
  width: 100%;
  border-collapse: collapse;
  th {
    padding: 5px;
    text-align: center;
    background-color: #ccc;
  }
  td {
    padding: 5px;
    background-color: #fff;
    border-bottom: 1px solid #e4e4e4;
  }
  .icon-delete {
    width: 20px;
    color: #858585;
    margin-right: 5px;
    @include font-size(2);
    &:hover {
      cursor: pointer;
      color: #656565;
    }
  }
}

/*select
----------------------------------*/
select.form-control {
  -moz-appearance: none;
  -webkit-appearance: none;
  background: #fff url(../img/icon_down.svg) no-repeat;
  background-size: 18px auto;
  background-position: right 0.15em center;
}
select.form-control::-ms-expand {
  display: none;
}

/*toggle-buttons
----------------------------------*/
.toggle-buttons {
  display: inline-block;
  text-align: left;
  padding-left: 7px;
}
.toggle-buttons label {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  margin-left: -7px;
  vertical-align: middle;
}
.toggle-buttons [type="radio"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}
/* appearance: none; for IE11 */
_:-ms-lang(x)::-ms-backdrop,
.toggle-buttons [type="radio"] {
  visibility: hidden;
}
.toggle-buttons .btn {
  position: relative;
  display: block;
  min-width: 1px;
  width: 100%;
  height: 100%;
  line-height: 32px;
  padding: 0;
  text-align: center;
  z-index: 1;
  font-weight: normal;
  color: $c-text;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 0;
  cursor: pointer;
  @include font-size(1.3);
  transition: all 0.15s ease-in-out;
}
.toggle-buttons .btn:hover {
  cursor: pointer;
  background-color: #e1e1e1;
}

.toggle-buttons :checked + .btn {
  color: #fff;
  background-color: $c-btn-blue;
  cursor: default;
}

.toggle-buttons :disabled + .btn {
  cursor: default;
  color: #8f8f8f;
  background-color: #f1f1f1;
}

.toggle-buttons label:first-child {
  .btn {
    border-radius: 4px 0 0 4px;
  }
}
.toggle-buttons label:last-child {
  .btn {
    border-radius: 0 4px 4px 0;
  }
}

/*toggle-switch
----------------------------------*/
.toggle-switch {
  display: inline-block;
  vertical-align: middle;
}
.cmn-toggle {
  position: absolute;
  margin-left: -9999px;
  visibility: hidden;
}
.cmn-toggle + label {
  display: block;
  position: relative;
  cursor: pointer;
  outline: none;
  user-select: none;
}
input.cmn-toggle-round + label {
  padding: 2px;
  width: 63px;
  height: 28px;
  background-color: #ccc;
  border-radius: 30px;
}
input.cmn-toggle-round + label:before,
input.cmn-toggle-round + label:after {
  display: block;
  position: absolute;
  top: 1px;
  left: 1px;
  bottom: 1px;
  content: "";
}
input.cmn-toggle-round + label:before {
  right: 1px;
  background-color: #f1f1f1;
  border-radius: 30px;
  transition: background 0.2s;
}
input.cmn-toggle-round + label:after {
  width: 26px;
  background-color: #fff;
  border-radius: 100%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  transition: margin 0.2s;
}
input.cmn-toggle-round:checked + label:before {
  background-color: #8ce196;
}
input.cmn-toggle-round:checked + label:after {
  margin-left: 36px;
}

/*checkbox,radiobox
----------------------------------*/
.checkbox {
  display: flex;
  align-items: center;
  width: fit-content;

  input[type="checkbox"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    position: relative;
    width: 16px;
    height: 16px;
    background-color: #fff;
    border: 1px solid #adb5bd;
    border-radius: 0;
  }
  input[type="checkbox"]:checked {
    content: url("../img/icon-ckeck-white.svg");
    border: none;
  }
  input[type="checkbox"]:disabled,
  input[type="checkbox"]:disabled:before {
    opacity: 0.75;
  }
  span {
    margin-left: 8px;
    white-space: nowrap;
  }
}

.ckbox {
  font-weight: bold;
  position: relative;
  display: inline-block;
  margin-right: 15px;
  line-height: 18px;
  text-align: left;
  span {
    &:empty {
      float: left;
    }
    &:before,
    &:after {
      line-height: 18px;
      position: absolute;
    }
    &:before {
      content: "";
      width: 16px;
      height: 16px;
      background-color: #fff;
      border: 1px solid #adb5bd;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
    }
    &:after {
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      width: 16px;
      height: 16px;
      content: url("../img/icon-ckeck-white.svg");
      font-size: 14px;
      font-weight: bold;
      text-align: center;
      color: #fff;
      background-color: #0866c6;
      line-height: 17px;
      display: none;
    }
  }
}
.ckbox input[type="checkbox"] {
  opacity: 0;
  margin: 0 10px 0 0;
}
.ckbox input[type="checkbox"]:checked + span:after {
  display: block;
}
.ckbox input[type="checkbox"][disabled] + span,
.ckbox input[type="checkbox"][disabled] + span:before,
.ckbox input[type="checkbox"][disabled] + span:after {
  opacity: 0.75;
}
.ckbox-inline {
  display: inline-block;
}

/* Radio Box */
.rdiobox {
  font-weight: bold;
  position: relative;
  display: inline-block;
  line-height: 18px;
  margin-right: 15px;
  span {
    &:before,
    &:after {
      line-height: 18px;
      position: absolute;
    }
    &:before {
      content: "";
      width: 16px;
      height: 16px;
      background-color: #fff;
      border: 1px solid #adb5bd;
      border-radius: 50px;
      top: 2px;
      left: 0;
    }
    &:after {
      content: "";
      width: 6px;
      height: 6px;
      background-color: #fff;
      border-radius: 50px;
      top: 7px;
      left: 5px;
      display: none;
    }
  }
}
.rdiobox input[type="radio"] {
  opacity: 0;
  margin: 0 10px 0 0;
}
.rdiobox input[type="radio"]:checked + span:before {
  border-color: transparent;
  background-color: #0866c6;
}
.rdiobox input[type="radio"]:checked + span:after {
  display: block;
}
.rdiobox input[type="radio"][disabled] + span,
.rdiobox input[type="radio"][disabled] + span:before,
.rdiobox input[type="radio"][disabled] + span:after {
  opacity: 0.75;
}
.rdiobox-inline {
  display: inline-block;
}

.ckbox-vertically,
.rdiobox-vertically {
  display: inline-block;
  vertical-align: top;
  label {
    display: block;
    margin-bottom: 5px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  &.chiba {
    margin: 10px 0;
    padding-left: 20px;
  }
  .othe-name {
    margin-left: 10px;
  }
  .schedule-other-name {
    margin-left: 5px;
  }
}

.worktime {
  display: inline-block;
  vertical-align: top;
  margin: 10px 0 0 0;
  padding-left: 20px;
  text-align: left;
  .worktime-start,
  .worktime-end {
    display: inline-block;
  }
}

/*btn
----------------------------------*/
.btn {
  display: inline-block;
  cursor: pointer;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  min-width: 100px;
  padding: 0.65rem 0.75rem;
  @include font-size(1.4);
  font-weight: bold;
  color: #fff;
  border-radius: 3px;
  border: 1px solid transparent;
  transition: all 0.15s ease-in-out;
  a {
    display: block;
    width: 100%;
    height: 100%;
    color: #fff;
  }
  &.qr {
    display: block;
    margin-bottom: 5px;
  }
  &.qr:last-child {
    display: block;
    margin-bottom: 0px;
  }
}

.btn-s {
  min-width: 60px;
  line-height: 1;
  @include font-size(1.3);
}

.btn-white {
  background-color: #fff;
  color: $c-text;
  border-color: #656565;
  &:hover {
    background-color: lightgray;
  }
}

.btn-blue {
  background-color: $c-btn-blue;
  &:hover {
    color: #fff;
    background-color: #0753a1;
    border-color: #064d95;
  }
}

.btn-light-blue {
  background-color: $c-btn-lightblue;
  &:hover {
    color: #fff;
    background-color: #3d7fcb;
    border-color: #3579c6;
  }
}

.btn-gray {
  background-color: $c-btn-gray;
  &:hover {
    color: #fff;
    background-color: #757575;
    border-color: #656565;
  }
}

.btn-close {
  &:hover {
    cursor: pointer;
    .icon-close {
      color: #656565;
    }
  }
}

.btn-dropdown {
  position: relative;
  &:after {
    position: absolute;
    content: "";
    top: 50%;
    right: 8px;
    width: 0;
    height: 0;
    margin-top: -3px;
    border-style: solid;
    border-width: 5px 4px 0 4px;
    border-color: #ffffff transparent transparent transparent;
  }
}

.btn:disabled {
  cursor: default;
  color: #8f8f8f;
  background-color: #d1d1d1;
  &:hover {
    color: #8f8f8f;
    background-color: #d1d1d1;
    border-color: #d1d1d1;
  }
}

.btn-dropdown:disabled {
  &:after {
    border-color: #8f8f8f transparent transparent transparent;
  }
}

.btn-apply {
  display: inline-block;
  cursor: pointer;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  min-width: 30px;
  padding: 0.45rem 0.55rem;
  @include font-size(1.2);
  font-weight: bold;
  color: #000;
  border-radius: 3px;
  border: 1px solid transparent;
  border-color: #6fb1f3;
  transition: all 0.15s ease-in-out;
  a {
    display: block;
    width: 100%;
    height: 100%;
    color: #fff;
  }
  background-color: #dddddd;
  &:hover {
    color: #fff;
    background-color: #757575;
    border-color: #656565;
  }
  &.select {
    background-color: #ff8d00;
    color: #ffffff;
  }
}
/*icon
----------------------------------*/
.icon {
  vertical-align: middle;
  transition: all 0.15s ease-in-out;
}

.icon[disabled] {
  color: #ccc;
  pointer-events: none;
}

.icon-close {
  color: #959595;
  font-weight: bold;
  @include font-size(1.8);
}

.icon-help-font {
  font-family: ヒラギノ角ゴ Pro, Hiragino Kaku Gothic Pro, 游ゴシック, YuGothic, メイリオ, Meiryo, sans-serif !important;
}

.icon-linkage {
  display: block;
  position: absolute;
  width: 100%;
  top: 3px;
  padding-left: 3px;
  &.approval {
    top: 50%;
    margin-top: -7px;
    left: 3px;
  }
}

.icon-linkage-editor {
  display: block;
  position: absolute;
  width: 45px;
  text-align: left;
  right: 0;
  bottom: 20px;
}

.icon-clipboard {
  position: absolute;
  width: 15px;
  bottom: 0;
  right: 0;
  z-index: 101;
}

/*ttl
----------------------------------*/
.page-ttl {
  margin: 15px 0;
  color: #2b333e;
  font-weight: bold;
  @include font-size(1.8);
  .toggle {
    display: inline-block;
    width: 22px;
    height: 22px;
    margin-left: 15px;
    text-align: center;
    vertical-align: middle;
    border-radius: 11px;
    color: #fff;
    @include font-size(2);
    transition: all 0.15s ease-in-out;
    &:hover {
      cursor: pointer;
      opacity: 0.7;
    }
  }
  .closed {
    transform: rotate(180deg);
  }
  .add {
    display: inline-block;
    margin-left: 15px;
    color: #313a47;
    vertical-align: middle;
    @include font-size(2.5);
    transition: all 0.15s ease-in-out;
    &:hover {
      cursor: pointer;
      color: #455265;
    }
  }
}

/*txt
----------------------------------*/
.txt-left {
  text-align: left;
}
.txt-center {
  text-align: center;
}
.txt-right {
  text-align: right;
}

.txt-top {
  vertical-align: top;
}
.txt-middle {
  vertical-align: middle;
}
.txt-bottom {
  vertical-align: bottom;
}

.txt-bold {
  font-weight: bold;
}
.txt-normal {
  font-weight: normal;
}

.txt-error {
  color: #ff0000;
}

.txt-disabled {
  color: #ccc;
  cursor: default;
  pointer-events: none;
}

/*inner
----------------------------------*/
.inner {
  min-width: 1280px;
  width: 100%;
  padding: 0 20px;
}

/*inazuma
----------------------------------*/
.inazuma-border {
  box-shadow: 0 0 0 2px #55a6f8 inset;
  background-color: #b4d3f3;
}
.inazuma {
  &.off {
    display: none;
  }
  position: absolute;
  padding: 20px;
  top: 45px;
  width: 100%;
  height: 182px;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 800;
  .panel-close {
    padding: 5px;
    position: absolute;
    top: 20px;
    right: 20px;
    img {
      width: 20px;
    }
  }
  p {
    color: #fff;
    text-align: center;
    @include font-size(2.4);
  }
  .disp-area {
    width: 200px;
    padding: 10px 20px;
    position: absolute;
    top: 20px;
    right: 70px;
    background-color: rgba(255, 255, 255, 1);
    border-radius: 3px;
    color: #000;
    span {
      font-weight: bold;
    }
  }
  .close {
    background-color: rgba(255, 255, 255, 1);
  }
  .button-area {
    position: absolute;
    right: 20px;
    bottom: 20px;
    .all-clear,
    .all-select {
      margin-right: 20px;
    }
  }
}
.inazuma-panel {
  .react-datepicker-wrapper {
    width: 120px;
  }
  .react-datepicker-popper {
    z-index: 1000;
  }
  .categoryNum {
    margin-right: 50px;
  }
  .disp-area {
    text-align: center;
    .txt-bold {
      font-weight: bold;
    }
  }
  .panel-top {
    margin-top: 30px;
    .condition {
      margin-left: 85px;
    }
  }
  .base-date {
    margin-left: 5px;
  }
  .inazuma-inner {
    display: grid;
    grid-template: auto / 250px 400px;
    gap: 20px;
    margin-top: 5px;

    .inazuma-panel-left {
      -ms-grid-column: 1;
      -ms-grid-row: 1;
      border: #aeaeae solid 1px;
      background-color: #fff;
      padding: 12px 14px;
      overflow-y: auto;

      &.disabled {
        background-color: unset;
      }
    }
    .inazuma-panel-right {
      -ms-grid-column: 3;
      -ms-grid-row: 1;
      border: #aeaeae solid 1px;
      padding: 10px;

      .w-base-date {
        width: 236px;
      }
      .w-past-line {
        width: 262px;
      }
      .w-interval {
        width: 236px;
      }
    }
    .form-target-date-list li {
      padding: 4px 0px;
    }
    .comment-info {
      @media screen and (-webkit-min-device-pixel-ratio: 0) {
        display: grid;
      }
      grid-template: auto / auto 1fr auto;
    }
    .comment-info > * {
      align-self: center;
    }
    .comment-info > button {
      grid-column: 1;
    }
    .baseDate {
      grid-column: 2;
      padding-left: 10px;
    }
    .trush {
      grid-column: 3;
      padding-left: 7px;
    }
    .icon-delete {
      width: 20px;
      color: #858585;
      margin-right: 5px;
      @include font-size(2);
      &:hover {
        cursor: pointer;
        color: #656565;
      }
    }
    .w-378 {
      width: 378px;
    }
  }
}
.modal-inazuma-confirm {
  width: 300px;
  text-align: center;
}

/* summary schedule*/

.schedule-panel {
  .disp-area {
    margin-bottom: 20px;
    text-align: center;
    .categoryNum {
      margin-right: 60px;
    }
  }

  .schedule-inner {
    width: 300px;
    margin: auto;
    .toggle-basis {
      text-align: center;
      margin-bottom: 15px;
      .toggle-label {
        display: inline-block;
        width: 80px;
        height: 34px;
        margin-left: -1px;
      }
    }

    .schedule-date-list {
      border: 1px solid #aeaeae;
      background-color: #fff;
      margin-top: 10px;
      padding: 12px 17px;
      overflow-y: auto;
      li {
        margin-bottom: 10px;
      }
      li:last-child {
        margin-bottom: 0px;
      }
    }
  }
}

.modal-schedule-confirm {
  width: 300px;
  text-align: center;
}

/*slide-contents
----------------------------------*/
.slide-contents {
  position: fixed;
  top: 0;
  right: 0;
  width: 386px;
  height: 100%;
  overflow-y: auto;
  color: $c-text;
  background-color: #f3f3f3;
  box-shadow: -1px 0 6px 0 rgba(4, 0, 0, 0.2);
  z-index: 400;
  .slide-head {
    position: relative;
    height: 46px;
    text-align: center;
    @include font-size(1.4);
    font-weight: bold;
    border-bottom: 1px solid #ccc;
    .btn-close {
      position: absolute;
      top: 0;
      right: 0;
      width: 40px;
      height: 46px;
    }
  }
}

/*modal
----------------------------------*/
.modal-bg {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background: rgba(0, 0, 0, 0.2);
}

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  overflow: hidden;
  outline: 0;
  background: rgba(0, 0, 0, 0.2);
}

.modal-drag-container {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}

.modal-dialog {
  position: absolute;
  width: auto;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  &.work-panel {
    width: 520px;
  }
  &.column-panel {
    width: 402px;
  }
  &.middleclass-panel {
    width: 362px;
  }
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  outline: 0;
}

.modal-header {
  cursor: move;
  position: relative;
  padding: 15px 0;
  text-align: center;
  font-weight: bold;
  border-bottom: 1px solid #e9ecef;
  .modal-ttl {
    @include font-size(1.5);
  }
  .btn-close {
    position: absolute;
    top: 0;
    right: 0;
    width: 50px;
    height: 100%;
    .icon {
      line-height: 50px;
    }
  }
}

#schedule-chiba {
  .modal-ttl {
    @include font-size(1.5);
    width: calc(100% - 50px);
    margin-left: 25px;
  }
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 20px;
  background-color: #f3f3f3;
  max-height: calc((100vh - 210px) * 0.9);
  overflow-y: auto;
  .form-text {
    text-align: center;
    margin-bottom: 15px;
    font-weight: bold;
  }
  .form-center {
    text-align: center;
  }
  .form-flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
  }
}
.modal-body-top {
  padding: 10px 20px;
  .form-row {
    width: 100%;
    text-align: center;
  }
  .form-group {
    float: none;
    display: inline-block;
    vertical-align: middle;
    text-align: left;
    &.txt-right {
      text-align: right;
    }
  }
}
.modal-body-left {
  float: left;
}
.modal-body-center {
  float: left;
  margin-left: 40px;
}
.modal-body-right {
  float: right;
}
.modal-body-confirm {
  text-align: center;
  width: 400px;
}
.modal-body-alert {
  text-align: center;
  width: 400px;
}
.modal-footer {
  padding: 12px 0;
  text-align: center;
  .btn {
    margin: 0 5px;
  }
  .disp-non {
    display: none;
  }
}

/*tooltip
----------------------------------*/
.tooltip {
  font-family: inherit !important;
  max-width: 380px;

  &.in {
    opacity: 1 !important;
    filter: alpha(opacity=100) !important;
  }
}

.tooltip-inner {
  padding: 10px !important;
  max-width: none !important;
  color: $c-text !important;
  background-color: #fff !important;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2), 0 1px 2px rgba(0, 0, 0, 0.2);
  .form-row:last-child {
    margin-bottom: 0;
  }
}

.tooltip-warnings {
  color: #f00;
  margin-bottom: 15px;

  > li {
    margin-bottom: 5px;
  }
}

.tooltip.right .tooltip-arrow {
  border-right-color: #fff !important;
}
.tooltip.top .tooltip-arrow {
  border-top-color: #fff !important;
}
.tooltip.left .tooltip-arrow {
  border-left-color: #fff !important;
}
.tooltip.bottom .tooltip-arrow {
  border-bottom-color: #fff !important;
}
.tooltip-comments {
  border: solid 1px #ddd;
}
.tooltip-comments:empty {
  border: none;
}
.tooltip-comment {
  padding: 5px;
}
.tooltip-comment:nth-child(n + 2) {
  border-top: dashed 1px #ddd;
}
.tooltip-comment-footer {
  color: #aaa;
  font-size: 11px;
  margin-top: 0.3em;
}
.tooltip-comment-user {
  margin-left: 0.5em;
}

// override react-tooltip styles.
.matrix-task-detail {
  background-color: transparent !important;
  border-radius: 0 !important;
  opacity: 1 !important;
  padding: 0 !important;
  text-align: left !important;

  &::after,
  &::before {
    display: none !important;
  }
}

.matrix-special-process {
  background-color: transparent !important;
  border-radius: 0 !important;
  opacity: 1 !important;
  padding: 0 !important;

  &::after,
  &::before {
    display: none !important;
  }
}

/*search-box
----------------------------------*/
.search-box-transition {
  display: flex;
  overflow: hidden;
  transition: height 0.5s;
}
.search-box {
  width: 1200px;
  padding: 20px;
  position: relative;
  margin-bottom: 20px;
  background-color: #fff;
  z-index: 300;
  .btn-area {
    text-align: right;
    &.pull-right {
      float: right;
      margin-top: -3px;
    }
    &.mt-0 {
      margin-top: 0;
    }
    .btn {
      margin-left: 5px;
    }
  }
}

/*tbl-top-area
----------------------------------*/
.tbl-top-area {
  margin-bottom: 10px;
  .tbl-top-left {
    float: left;
  }
  .tbl-top-right {
    float: right;
    margin-top: 6px;
  }
  .ckbox-area {
    float: left;
    margin-top: 8px;
    margin-right: 5px;
  }
  .page-ttl {
    float: left;
    margin: 5px 15px 0 0;
  }
  .btn-search {
    float: left;
    .icon-search {
      margin-right: 3px;
      @include font-size(2);
    }
  }
  .item-num {
    float: left;
    font-size: 1.4rem;
    padding: 0.65rem 0;
  }
  .btn-dropdown-area {
    position: relative;
    z-index: 200;
    float: left;
    width: 170px;
    margin-right: 10px;

    .symbol-empty::before {
      border: dotted 1px #999;
      content: "";
      display: inline-block;
      height: 12px;
      vertical-align: middle;
      width: 12px;
    }

    .symbol-empty.is-selected::before {
      border-color: #fff;
    }

    li.disp-non {
      display: none;
    }
  }
  .btn-dropdown {
    width: 100%;
    padding-left: 0;
    margin-right: 5px;
    .icon {
      margin-right: 3px;
      @include font-size(2);
      &.icon-check_box {
        margin-right: 8px;
        @include font-size(1.8);
      }
      &.icon-check_box_disabled {
        margin-right: 8px;
        @include font-size(1.8);
      }
    }
  }
  .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: #fff;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2), 0 1px 2px rgba(0, 0, 0, 0.2);
    li {
      padding: 8px 10px;
      transition: all 0.15s ease-in-out;
      .icon-file {
        width: 22px;
        height: auto;
        margin-right: 5px;
        vertical-align: middle;
      }
      &:hover {
        cursor: pointer;
        background-color: #e5e5e5;
      }
      span {
        display: table-cell;
        vertical-align: middle;
      }
      a {
        color: $c-text;
        display: block;
      }
    }
  }
  .icon-help,
  .icon-cached {
    color: #313a47;
    @include font-size(2.6);
    &:hover {
      cursor: pointer;
      color: #455265;
    }
  }
  .icon-help {
    margin-left: 5px;
    margin-right: 10px;
  }
  .icon-cached {
    display: inline-block;
    margin-left: 8px;
  }
}

/*tbl
----------------------------------*/
.tbl-basic {
  width: 100%;
  border-collapse: collapse;
  tbody {
    background-color: #fff;
  }
  th {
    padding: 7px 10px;
    text-align: center;
    background-color: #ccc;
    border-right: 1px solid #fff;
    border-bottom: 1px solid #eff0f3;
    &:last-child {
      border-right: 1px solid #ccc;
    }
  }
  td {
    padding: 10px;
    border-right: 1px solid #e4e4e4;
    border-bottom: 1px solid #ccc;
    &:last-child {
      border-right: 1px solid #fff;
    }
    a {
      text-decoration: underline;
    }
    .icon {
      float: right;
      @include font-size(1.6);
      &:hover {
        cursor: pointer;
        color: #555;
      }
    }
    .ckbox {
      margin-right: 0;
      margin-left: 5px;
      &.disp-non {
        display: none;
      }
    }
  }
}

.tbl-data {
  th {
    padding: 5px;
    @include font-size(1.2);
  }
  td {
    padding: 5px 7px;
    @include font-size(1.2);
  }
}

td.cell-blue {
  background-color: #e2f2fe;
}
td.cell-yellow {
  background-color: #feff53;
}
td.cell-orange {
  background-color: #ffa11e;
}
td.cell-red {
  background-color: #fb9b9b;
}

html.win .tbl-head-adjusted {
  margin-right: 17px;
}
html.linux .tbl-head-adjusted {
  margin-right: 17px;
}

html.linux .tbl-head-adjusted {
  margin-right: 17px;
}

/*loading
----------------------------------*/
.loading-layout {
  animation: spin-transparent 1s infinite linear;
  -webkit-animation: spin-transparent 1s infinite linear;
  -moz-animation: spin-transparent 1s infinite linear;
}

.loading {
  position: relative;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    z-index: 1100;
    width: 40px;
    height: 40px;
    border-radius: 40px;
    border: 6px solid #0866c6;
    border-right-color: transparent;
    animation: spin-transparent 1s infinite linear;
    -webkit-animation: spin-transparent 1s infinite linear;
    -moz-animation: spin-transparent 1s infinite linear;
  }

  &--list {
    min-height: 250px;

    &::after {
      background-color: transparent;
      background-size: 46px;
    }

    > table {
      opacity: 0.5;
    }
  }

  &--dialog {
    &::after {
      background-position: right 20px center;
      background-size: 24px;
    }
  }

  &--button {
    &::after {
      width: 18px;
      height: 18px;
      border: 3px solid #0866c6;
      border-right-color: transparent;
      border-radius: 50%;
      left: auto;
      right: 5px;
    }
  }
}

.loading-small {
  position: relative;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    z-index: 1100;
    width: 20px;
    height: 20px;
    border-radius: 20px;
    border: 3px solid #0866c6;
    border-right-color: transparent;
    animation: spin-transparent 1s infinite linear;
    -webkit-animation: spin-transparent 1s infinite linear;
    -moz-animation: spin-transparent 1s infinite linear;
  }

  &-download {
    position: absolute;
    left: 180px;
    top: 0px;
    bottom: 0px;
  }

  &-matrix {
    right: -16px;
  }

  &-schedule-permit-table {
    left: 70px;
    bottom: 8px;
  }
}

@keyframes spin-transparent {
  0% {
    transform: rotate(0deg);
    opacity: 0.6;
  }
  50% {
    transform: rotate(180deg);
    opacity: 1;
  }
  100% {
    transform: rotate(360deg);
    opacity: 0.6;
  }
}

/*progress
----------------------------------*/
.progress-container {
  background-color: #eee;
  border-radius: 3px;
  display: inline-block !important;
  height: 6px;
  overflow: hidden;
  position: relative;
  vertical-align: middle;
  width: 150px;
}

.progress-value {
  background: $c-blue;
  bottom: 0;
  left: 0;
  position: absolute;
  top: 0;
  transition: width 0.5s ease;
}

/* React Contextmenu
----------------------------------*/
.react-contextmenu {
  background: #fff;
  border: solid 1px #ccc;
  border-radius: 3px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.1);
  padding: 7px 0;
  z-index: 1000;
}

.react-contextmenu-item {
  cursor: pointer;
  padding: 7px 15px;

  &:hover {
    background-color: #eee;
  }
}

/* React Datepicker
----------------------------------*/
#app {
  .react-datepicker {
    font-size: 1em;
  }
  .react-datepicker__header {
    padding-top: 0.8em;
  }
  .react-datepicker__month {
    margin: 0.4em 1em;
  }
  .react-datepicker__day-name,
  .react-datepicker__day {
    width: 1.9em;
    line-height: 1.9em;
    margin: 0.166em;
  }
  .react-datepicker__current-month {
    font-size: 1em;
  }
  .react-datepicker__navigation {
    top: 0.5em;
    line-height: 1.7em;
    border: 0.45em solid transparent;
  }
  .react-datepicker__navigation--previous {
    left: 9px;
  }
  .react-datepicker__navigation--next {
    right: 9px;
  }

  .react-datepicker__input-container input {
    display: block;
    width: 100%;
    padding: 0.45rem 0.75rem;
    line-height: 1.25;
    @include font-size(1.3);
    background-color: #fff;
    background-image: none;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;

    &:focus {
      background-color: #fff;
      border-color: #55a6f8;
      outline: none;
    }

    &:disabled {
      background-color: #e9ecef;
      opacity: 1;
    }
  }

  .react-datepicker__day--holidays {
    border-radius: 1.2rem;
    background-color: #eda2d9;
    color: #000;
    &.react-datepicker__day--selected {
      border-radius: 0.3rem;
      background-color: #216ba5;
      color: #fff;
    }
  }
}

.dataimport {
  .import-search {
    .react-datepicker-wrapper {
      vertical-align: middle;
    }
  }

  .btn-dropdown-area {
    &.top {
      z-index: 400;
    }
    .dropdown-menu {
      &.dl {
        width: 190px;
      }
    }
  }

  thead.list-head,
  tbody.list-body {
    display: block;
  }
  tbody.list-body {
    overflow-y: scroll;
    max-height: 558px;
    padding-bottom: 1px; // for IE bugfix.
  }
  th:nth-child(1),
  td:nth-child(1) {
    max-width: 40px;
    min-width: 40px;
  }
  th:nth-child(2),
  td:nth-child(2) {
    width: 100%;
  }
  th:nth-child(3) {
    max-width: 120px;
    min-width: 120px;
  }
  td:nth-child(3) {
    max-width: 120px;
    min-width: 120px;
    text-align: left;
  }
  th:nth-child(4) {
    max-width: 120px;
    min-width: 120px;
  }
  td:nth-child(4) {
    max-width: 120px;
    min-width: 120px;
    text-align: left;
  }
  th:nth-child(5) {
    max-width: 120px;
    min-width: 120px;
  }
  td:nth-child(5) {
    max-width: 120px;
    min-width: 120px;
    text-align: left;
  }
  th:nth-child(6),
  td:nth-child(6) {
    max-width: 200px;
    min-width: 200px;
  }
  th:nth-child(7) {
    max-width: 120px;
    min-width: 120px;
  }
  td:nth-child(7) {
    max-width: 120px;
    min-width: 120px;
    text-align: left;
  }
  th:nth-child(8),
  td:nth-child(8) {
    max-width: 80px;
    min-width: 80px;
  }
  th:nth-child(9),
  td:nth-child(9) {
    max-width: 60px;
    min-width: 60px;
  }
}

.asset {
  .search-box {
    .react-datepicker-wrapper {
      vertical-align: middle;
    }
  }

  .btn-dropdown-area {
    &.top {
      z-index: 400;
    }
    .dropdown-menu {
      &.dl {
        width: 190px;
      }
    }
  }

  .asset-table-row {
    table-layout: fixed;
  }

  thead.list-head,
  tbody.list-body {
    display: block;
  }
  tbody.list-body {
    overflow-y: scroll;
    max-height: 558px;
    padding-bottom: 1px; // for IE bugfix.
  }
  th:nth-child(1),
  td:nth-child(1) {
    max-width: 120px;
    min-width: 120px;
  }
  th:nth-child(2) {
    width: 100%;
  }
  th:nth-child(3) {
    max-width: 180px;
    min-width: 180px;
  }
  th:nth-child(4) {
    max-width: 110px;
    min-width: 110px;
  }
  th:nth-child(5) {
    max-width: 400px;
    min-width: 400px;
  }
  th:nth-child(6) {
    max-width: 70px;
    min-width: 70px;
  }

  // th:nth-child(2), td:nth-child(2) {
  //   width: 100%;
  // }
  // th:nth-child(3) {
  //   max-width: 180px; min-width: 180px;
  // }
  // td:nth-child(3) {
  //   max-width: 180px; min-width: 180px;
  //   text-align: left;
  // }
  // th:nth-child(4) {
  //   max-width: 110px; min-width: 110px;
  // }
  // td:nth-child(4) {
  //   max-width: 110px; min-width: 110px;
  //   text-align: left;
  // }
  // th:nth-child(5) {
  //   max-width: 400px; min-width: 400px;
  // }
  // td:nth-child(5) {
  //   max-width: 400px; min-width: 400px;
  //   text-align: left;
  // }
  // th:nth-child(6), td:nth-child(6) {
  //   max-width: 70px; min-width: 70px;
  // }
}

// CSS Moduleにしたい
.comment {
  .comment-search-highlight {
    background-color: #ffd680;
    padding-top: 3px;
    padding-bottom: 2px;
  }
}

.system {
  .search-box {
    .react-datepicker-wrapper {
      vertical-align: middle;
    }
  }

  thead.list-head,
  tbody.list-body {
    display: block;
  }
  tbody.list-body {
    overflow-y: scroll;
    padding-bottom: 1px; // for IE bugfix.
  }

  th:nth-child(1),
  td:nth-child(1) {
    max-width: 41px;
    min-width: 41px;
  }
  th:nth-child(2),
  td:nth-child(2) {
    width: 100%;
  }
  th:nth-child(3),
  td:nth-child(3) {
    max-width: 120px;
    min-width: 120px;
  }
  th:nth-child(4),
  td:nth-child(4) {
    max-width: 120px;
    min-width: 120px;
  }
  th:nth-child(5),
  td:nth-child(5) {
    max-width: 15vw;
    min-width: 15vw;
  }
  th:nth-child(6),
  td:nth-child(6) {
    max-width: 100px;
    min-width: 100px;
  }
  th:nth-child(7),
  td:nth-child(7) {
    max-width: 100px;
    min-width: 100px;
  }
  th:nth-child(8),
  td:nth-child(8) {
    max-width: 140px;
    min-width: 140px;
  }
  th:nth-child(9),
  td:nth-child(9) {
    max-width: 120px;
    min-width: 120px;
  }
  th:nth-child(10),
  td:nth-child(10) {
    max-width: 60px;
    min-width: 60px;
  }
}

.system-editor {
  .react-datepicker__input-container {
    width: 140px;
  }
}

#viewer-list {
  thead.list-head,
  tbody.list-body {
    display: block;
  }
  tbody.list-body {
    overflow-y: scroll;
  }

  th:nth-child(1),
  td:nth-child(1) {
    max-width: 41px;
    min-width: 41px;
  }
  th:nth-child(2),
  td:nth-child(2) {
    max-width: 80px;
    min-width: 80px;
  }
  th:nth-child(3),
  td:nth-child(3) {
    max-width: 60px;
    min-width: 60px;
  }
  th:nth-child(4),
  td:nth-child(4) {
    max-width: 80px;
    min-width: 80px;
  }
  th:nth-child(5),
  td:nth-child(5) {
    max-width: 80px;
    min-width: 80px;
  }
  th:nth-child(6),
  td:nth-child(6) {
    max-width: 80px;
    min-width: 80px;
  }
  th:nth-child(7),
  td:nth-child(7) {
    max-width: 100px;
    min-width: 100px;
  }
  th:nth-child(8),
  td:nth-child(8) {
    max-width: 100px;
    min-width: 100px;
  }
  th:nth-child(9),
  td:nth-child(9) {
    max-width: 80px;
    min-width: 80px;
  }
  th:nth-child(10),
  td:nth-child(10) {
    width: 100%;
  }
  th:nth-child(11),
  td:nth-child(11) {
    max-width: 90px;
    min-width: 90px;
  }
  th:nth-child(12),
  td:nth-child(12) {
    max-width: 80px;
    min-width: 80px;
  }
  th:nth-child(13),
  td:nth-child(13) {
    max-width: 80px;
    min-width: 80px;
  }
  th:nth-child(14),
  td:nth-child(14) {
    max-width: 100px;
    min-width: 100px;
  }
  th:nth-child(15),
  td:nth-child(15) {
    max-width: 60px;
    min-width: 60px;
  }
}

/*display
----------------------------------*/
.d-n {
  display: none;
}
.d-b {
  display: block;
}
.d-i {
  display: inline;
}
.d-f {
  display: flex;
}
.d-g {
  display: grid;
}
.d-ib {
  display: inline-block;
  vertical-align: middle;
}
.d-if {
  display: inline-flex;
}

/*text-align
----------------------------------*/
.ta-u {
  text-align: unset;
}
.ta-l {
  text-align: left;
}
.ta-c {
  text-align: center;
}
.ta-r {
  text-align: right;
}

/*width
----------------------------------*/

@for $i from 1 through 100 {
  .react-datepicker-wrapper.w-#{$i * 10},
  .w-#{$i * 10} {
    width: $i * 10px;
  }
}

.w-208 {
  width: 208px;
}

.w-212 {
  width: 212px;
}

.w-1060 {
  width: 1060px;
}

/*max-width
----------------------------------*/
.mw-90 {
  max-width: 90px;
}
.mw-160 {
  max-width: 160px;
}
.mw-210 {
  max-width: 210px;
}

/*margin
----------------------------------*/
.mt-0 {
  margin-top: 0;
}
.mt-5 {
  margin-top: 5px;
}
.mt-10 {
  margin-top: 10px;
}
.mt-15 {
  margin-top: 15px;
}
.mt-20 {
  margin-top: 20px;
}
.mt-25 {
  margin-top: 25px;
}
.mt-30 {
  margin-top: 30px;
}
.mt-35 {
  margin-top: 35px;
}
.mt-40 {
  margin-top: 40px;
}
.mt-45 {
  margin-top: 45px;
}
.mt-50 {
  margin-top: 50px;
}

.mt-n10 {
  margin-top: -10px;
}

.mr-0 {
  margin-right: 0;
}
.mr-5 {
  margin-right: 5px;
}
.mr-10 {
  margin-right: 10px;
}
.mr-15 {
  margin-right: 15px;
}
.mr-20 {
  margin-right: 20px;
}
.mr-25 {
  margin-right: 25px;
}
.mr-30 {
  margin-right: 30px;
}
.mr-35 {
  margin-right: 35px;
}
.mr-40 {
  margin-right: 40px;
}
.mr-45 {
  margin-right: 45px;
}
.mr-50 {
  margin-right: 50px;
}

.mb-0 {
  margin-bottom: 0;
}
.mb-5 {
  margin-bottom: 5px;
}
.mb-10 {
  margin-bottom: 10px;
}
.mb-15 {
  margin-bottom: 15px;
}
.mb-20 {
  margin-bottom: 20px;
}
.mb-25 {
  margin-bottom: 25px;
}
.mb-30 {
  margin-bottom: 30px;
}
.mb-35 {
  margin-bottom: 35px;
}
.mb-40 {
  margin-bottom: 40px;
}
.mb-45 {
  margin-bottom: 45px;
}
.mb-50 {
  margin-bottom: 50px;
}

.ml-0 {
  margin-left: 0;
}
.ml-5 {
  margin-left: 5px;
}
.ml-10 {
  margin-left: 10px;
}
.ml-15 {
  margin-left: 15px;
}
.ml-20 {
  margin-left: 20px;
}
.ml-25 {
  margin-left: 25px;
}
.ml-30 {
  margin-left: 30px;
}
.ml-35 {
  margin-left: 35px;
}
.ml-40 {
  margin-left: 40px;
}
.ml-45 {
  margin-left: 45px;
}
.ml-50 {
  margin-left: 50px;
}
.ml-80 {
  margin-left: 80px;
}
.ml-90 {
  margin-left: 90px;
}
.ml-100 {
  margin-left: 100px;
}
.ml-110 {
  margin-left: 110px;
}
.ml-130 {
  margin-left: 130px;
}

.mw-220 {
  max-width: 220px;
}
.mw-580 {
  max-width: 580px;
}

.p-5 {
  padding: 5px;
}

.relative {
  position: relative;
}

/*vertical-align
----------------------------------*/
.va-btm {
  vertical-align: bottom;
}

.dropdown-box {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: #fff;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2), 0 1px 2px rgba(0, 0, 0, 0.2);
  div {
    padding: 8px 10px;
    transition: all 0.15s ease-in-out;
    .icon-file {
      width: 22px;
      height: auto;
      margin-right: 5px;
      vertical-align: middle;
    }
    &:hover {
      cursor: pointer;
      background-color: #e5e5e5;
    }
    span {
      display: table-cell;
      vertical-align: middle;
    }
    a {
      color: $c-text;
      display: block;
    }
  }
}

.preview-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9999;

  &-overlay {
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.8);
    width: 100vw;
    height: 100vh;
  }

  &-close-btn {
    position: absolute;
    top: 20px;
    right: 20px;

    &:hover {
      .preview-modal-close-btn-icon {
        &::after,
        &::before {
          background: #fff;
        }
      }
    }

    &-icon {
      display: block;
      width: 44px;
      height: 44px;
      border: 2px solid transparent;
      border-radius: 40px;
    }

    &-icon::after,
    &-icon::before {
      content: "";
      display: block;
      position: absolute;
      width: 28px;
      height: 3px;
      background: rgba(255, 255, 255, 0.75);
      transform: rotate(45deg);
      border-radius: 5px;
      top: 20px;
      left: 6px;
    }

    &-icon::after {
      transform: rotate(-45deg);
    }
  }

  &-body {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100vh;
    max-width: 1000px;
    padding: 10px 0;
  }
}
