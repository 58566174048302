#sx-layout {
  .btn-blue {
    background-color: $c-btn-orange;
    &:hover {
      background-color: darken($c-btn-orange, 5%);
      border-color: darken($c-btn-orange, 5%);
    }
  }
}

.sx-layout-tooltip {
  position: fixed;
  top: 185px;
  bottom: 15px;
  width: 260px !important;
  z-index: 9999;
  padding-top: 17px;
  overflow-x: hidden;
  overflow-y: auto;
}

.sx-layout-tooltip ~ .left-aligned {
  left: 15px;
}

.sx-layout-tooltip ~ .right-aligned {
  right: 15px;
}

.machine-wrapper .moveable-control-box {
  --moveable-color: rgba(0, 0, 0, 0) !important;
}

.area-wrapper .relative > .tooltip,
.machine-wrapper .relative > .tooltip {
  visibility: hidden;
}

.area-wrapper .relative:hover > .tooltip,
.machine-wrapper .relative:hover > .tooltip {
  visibility: visible;
}

#sx-layout {
  .react-datepicker {
    .react-datepicker__day--selected {
      background-color: $c-btn-orange;

      &:hover {
        background-color: #d3940a;
      }
    }
  }

  .fade-in {
    animation: plotmap-fade-in 0.1s;
    -webkit-animation: plotmap-fade-in 0.1s;
    -moz-animation: plotmap-fade-in 0.1s;
  }

  @keyframes plotmap-fade-in {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
}

#layout-target-submenu {
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px, rgba(0, 0, 0, 0.1) 0 0 3px, rgba(0, 0, 0, 0.15) 0 0 0 1px;
}

#layout-target-submenu-arrow,
#layout-target-submenu-arrow::before {
  position: absolute;
  width: 16px;
  height: 16px;
  background: #f3f2ef;
  box-shadow: rgba(0, 0, 0, 0.18) 0 0 0 1px;
}

#layout-target-submenu-arrow {
  visibility: hidden;
  z-index: -1;
}

#layout-target-submenu-arrow::before {
  visibility: visible;
  content: "";
  transform: rotate(45deg);
}

#layout-target-submenu[data-popper-placement^="top"] > #layout-target-submenu-arrow {
  bottom: 4px;
  left: 44%;
}

#layout-target-submenu[data-popper-placement^="bottom"] > #layout-target-submenu-arrow {
  top: -8px;
  left: 44%;
}

#layout-target-submenu[data-popper-placement^="left"] > #layout-target-submenu-arrow {
  right: -8px;
  top: 44%;
}

#layout-target-submenu[data-popper-placement^="right"] > #layout-target-submenu-arrow {
  left: -8px;
  top: 44%;
}
