#layoutNotifications {
  z-index: 1001;
  position: fixed;
  top: 65px;
  bottom: 0;
  right: 0;
  width: 350px;
  transition: transform 0.2s linear;
  transform: translateX(100%);
  background-color: rgba(86, 86, 86, 0.7);
  background-color: #ffffff;
  color: #000000;
  overflow-y: auto;

  // NOTE(joshua-hashimoto): ドロワーの開閉用
  &.open {
    transform: translateX(0);
    box-shadow: -2px 0 10px 0 rgba(4, 0, 0, 0.23);
  }

  .sticky-header {
    position: sticky;
    z-index: 100;
    top: 0;
  }

  #drawer-header {
    display: flex;
    justify-content: space-between;
    background-color: #cccccc;
    padding: 0 0.5rem;
    align-items: center;
    line-height: 45px;

    #title-area {
      display: flex;
      justify-content: center;

      #title-text {
        font-size: 1.4rem;
        margin-right: 5px;
      }
      #refresh-icon {
        width: 23px;
        display: flex;
        align-items: center;
        cursor: pointer;
      }
    }

    #close-button {
      margin-right: 5px;
      width: 15px;
      display: flex;
      align-items: center;
      cursor: pointer;
    }
  }

  #layout-notification-list {
    height: 100%;
  }

  .layout-notification {
    background: #fff;
    padding: 1rem 1.2rem;
    border-bottom: 1px solid #cccccc;
    cursor: default;
    font-weight: 400;
    font-weight: bold;

    &.is-new {
      background: #fffad8;
    }

    &.is-read {
      background: #eff0f3;
    }

    .layout-notification-header {
      display: flex;
      justify-content: flex-start;

      .subject {
        font-size: 1.2em;
        margin-left: 5px;
        line-height: 3;
      }
    }

    .layout-notification-content {
      padding-bottom: 5px;
      .layout-notification-detail-row {
        display: flex;
        line-height: 1.4;

        .layout-notification-detail-row-label {
          min-width: 10rem;
          text-align: right;
        }
      }
    }
  }
}

.pointer-cursor {
  cursor: pointer !important;
}

#layout-notification-bell {
  width: 50px;
  height: 45px;
  position: relative;
  transition: all 0.15s ease-in-out;
  font-size: 1.8rem;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    cursor: pointer;
    background-color: $c-blue;
  }

  > .label-badge {
    background: #cc0000;
    color: white;
    font-size: 8px;
    position: absolute;
    top: 5px;
    right: 4px;
    border-radius: 50%;
    border: 1px solid #ffc79e;
    height: 20px;
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
