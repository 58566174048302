.toast {
  bottom: 20px;
  position: fixed;
  right: 20px;
  z-index: 1500;

  &-content {
    align-items: center;
    background-color: #fff;
    border: solid 2px #ccc;
    border-radius: 5px;
    display: flex;
    height: 100px;
    justify-content: center;
    padding: 15px;
    transition: opacity 0.5s ease;
    width: 300px;
  }

  &-content + &-content {
    margin-top: 15px;
  }

  &-error {
    background-color: #fee;
    border-color: #f00;
  }

  &-enter {
    opacity: 0;
    &-active {
      opacity: 1;
    }
  }
  &-exit {
    opacity: 1;
    &-active {
      opacity: 0;
    }
  }
}
