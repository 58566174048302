@charset "utf-8";

.qr-table-row tr {
}

.qr-table-row tr th,
.qr-table-row tr td {
  // height: 42px;
}

.qr-table-row tr th:nth-child(1),
.qr-table-row tr td:nth-child(1) {
  max-width: 120px;
  min-width: 120px;
  width: 120px;
}

.qr-table-row tr th:nth-child(2),
.qr-table-row tr td:nth-child(2) {
  max-width: 120px;
  min-width: 120px;
  width: 120px;
}

.qr-table-row tr th:nth-child(3),
.qr-table-row tr td:nth-child(3) {
  max-width: 120px;
  min-width: 120px;
  width: 120px;
}

.qr-table-row tr th:nth-child(4),
.qr-table-row tr td:nth-child(4) {
  max-width: 120px;
  min-width: 120px;
  width: 120px;
}

.qr-table-row tr th:nth-child(5),
.qr-table-row tr td:nth-child(5) {
  // width: 100%;
}

.qr-table-row tr th:nth-child(6),
.qr-table-row tr td:nth-child(6) {
  max-width: 180px;
  min-width: 180px;
  width: 180px;
}

.qr-table-row tr th:nth-child(7),
.qr-table-row tr td:nth-child(7) {
  max-width: 180px;
  min-width: 180px;
  width: 180px;
}

.qr-table-row tr th:nth-child(8),
.qr-table-row tr td:nth-child(8) {
  max-width: 100px;
  min-width: 100px;
  width: 100px;
}

.qr-table-row tr th:nth-child(9),
.qr-table-row tr td:nth-child(9) {
  max-width: 100px;
  min-width: 100px;
  width: 100px;
}

// .qr-table-row tr th.colS,
// .qr-table-row tr td.colS {
//   flex: 1;
// }
//
// .qr-table-row tr th.colL,
// .qr-table-row tr td.colL, {
//   flex: 2;
// }
