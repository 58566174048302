@charset "utf-8";

//-----------------------------------
// 変数
//-----------------------------------
//レイアウト
$inner-width: 1200px;

//文字色
$c-text: #111;
$c-text-navy: #aaa;
$c-text-error: #c00;
$c-text-light-navy: #aaa;

//テーマ色
$c-blue: #0866c6;
$c-lightblue: #5b93d3;
$c-navy: #252c36;
$c-lightgray: #dfdfdf;
$c-orange: #d5a541;

//ボタン色
$c-btn-blue: #0866c6;
$c-btn-lightblue: #5b93d3;
$c-btn-gray: #959595;
$c-btn-orange: #dea321;

//ベンダープレフィックス
$prefixList: -webkit-, -moz-, -ms-, -o-, null;

//calc
@mixin calc($property, $expression) {
  #{$property}: -moz-calc(#{$expression});
  #{$property}: -o-calc(#{$expression});
  #{$property}: -webkit-calc(#{$expression});
  #{$property}: calc(#{$expression});
}

//-----------------------------------
// 文字サイズrem対応
//-----------------------------------
@mixin font-size($s: 1.4) {
  font-size: ($s * 10) + px;
  font-size: $s + rem;
}

//-----------------------------------
// その他汎用系クラス
//-----------------------------------
