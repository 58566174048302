@charset "utf-8";

.tbl-approvals-body,
.tbl-inspections-body {
  overflow-y: scroll;
  padding-bottom: 1px; // for IE bugfix.
  // height: calc(100vh - 420px);

  &.is-large {
    // height: calc(100vh - 197px);
  }
}

/*list
----------------------------------*/
.list-title {
  font-weight: bold;
  font-size: 1.2rem;
}

.list-table-row {
  .row-processing {
    td {
      background-color: rgba(0, 0, 0, 0.5);
    }
  }
  .approval-linkage {
    position: absolute;
    margin-top: -8px;
  }
  .approval-status {
    position: absolute;
    left: 50%;
    margin: -8px 0 0 -6px;
  }
}

.list-table-row .row-processing {
  .cell-inner {
    position: relative;
    // display: table-cell;
    // width: 100%;
    height: 100%;
    vertical-align: middle;
    z-index: 100;
  }
}

.list-table-row .row-not-allowed * {
  cursor: not-allowed;
}

.list-table-row .row-processing {
  .cell-inner::after {
    background: url(../img/grid_spinner.svg) no-repeat center;
    background-size: 10%;
    bottom: 0;
    content: "";
    display: block;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 100;
  }
}

.list-table-row tr.grid-cell-error {
  border: 2px solid #f00;
}

.list-table-row tr th:nth-child(1),
.list-table-row tr td:nth-child(1) {
  max-width: 60px;
  min-width: 60px;
  width: 60px;
}

.list-table-row tr th.col2,
.list-table-row tr td.col2 {
  max-width: 80px;
  min-width: 80px;
  width: 80px;
}

.list-table-row tr th.col3,
.list-table-row tr td.col3 {
  max-width: 80px;
  min-width: 80px;
  width: 80px;
}

.list-table-row tr th.col4,
.list-table-row tr td.col4 {
  max-width: 80px;
  min-width: 80px;
  width: 80px;
}

.list-table-row tr th.col5,
.list-table-row tr td.col5 {
  max-width: 80px;
  min-width: 80px;
  width: 80px;
}

.list-table-row tr th.col6,
.list-table-row tr td.col6 {
  max-width: 100px;
  min-width: 100px;
  width: 100px;
}

.list-table-row tr th.col7,
.list-table-row tr td.col7 {
  max-width: 80px;
  min-width: 80px;
  width: 80px;
}

.list-table-row tr th.col8,
.list-table-row tr td.col8 {
  max-width: 80px;
  min-width: 80px;
  width: 80px;
}

.list-table-row tr th.col9,
.list-table-row tr td.col9 {
  min-width: 100px;
  width: 100px;
}

.list-table-row tr th.col10,
.list-table-row tr td.col10 {
  max-width: 80px;
  min-width: 80px;
  width: 80px;
}

.list-table-row tr th.col11,
.list-table-row tr td.col11 {
  min-width: 100px;
  width: 100px;
}

.list-table-row tr th:nth-child(12),
.list-table-row tr td:nth-child(12) {
  max-width: 120px;
  min-width: 120px;
  width: 120px;
}

.list-table-row tr th:nth-child(13),
.list-table-row tr td:nth-child(13) {
  max-width: 100px;
  min-width: 100px;
  width: 100px;
}

.list-table-row tr th:nth-child(14),
.list-table-row tr td:nth-child(14) {
  max-width: 60px;
  min-width: 60px;
  width: 60px;
}

.list-table-row tr th:nth-child(15),
.list-table-row tr td:nth-child(15) {
  width: 80px;
}

.list-table-row tr th.insp_col1,
.list-table-row tr td.insp_col1 {
  width: 50px;
}

.list-table-row tr th.insp_col2,
.list-table-row tr td.insp_col2 {
  width: 11.2vw;
}

.list-table-row tr th.insp_col3,
.list-table-row tr td.insp_col3 {
  width: 10.5vw;
}

.list-table-row tr th.insp_col5,
.list-table-row tr td.insp_col5 {
  width: 13.5vw;
}

.list-table-row tr th.insp_col4,
.list-table-row tr td.insp_col4,
.list-table-row tr th.insp_col6,
.list-table-row tr td.insp_col6 {
  width: 120px;
}

.list-table-row tr th.insp_col7,
.list-table-row tr td.insp_col7 {
  width: 9vw;
}

.list-table-row tr th.insp_col9,
.list-table-row tr td.insp_col9 {
  width: 7vw;
}

.list-table-row tr th.insp_col8,
.list-table-row tr td.insp_col8 {
  width: 150px;
}

.list-table-row tr th.insp_col10,
.list-table-row tr td.insp_col10,
.list-table-row tr th.insp_col11,
.list-table-row tr td.insp_col11 {
  width: 100px;
}

.list-table-row tr th.insp_col12,
.list-table-row tr td.insp_col12 {
  width: 80px;
}

.schedule {
  .tbl-top {
    float: left;
    .icon-reload {
      display: inline-block;
      margin-right: 10px;
      margin-top: -2px;
      color: #ffffff;
      font-size: 2.2rem;
    }
    .icon-reload:before {
      content: url("../img/icon-reload.svg");
    }
    .schedule-reload {
      margin-left: 20px;
    }
    .permit-table-download-button {
      margin-left: 20px;
      height: 35.51px;
    }
  }

  .tbl-schedules {
    &.chita {
      @media all and (-ms-high-contrast: none) {
        max-width: 1236px;
      }
      @supports (-ms-ime-align: auto) {
        max-width: 1236px;
      }
      .tbl-top-area {
        max-width: 1200px;
      }
    }
    &.chiba {
      .tbl-top-area {
        .tbl-top-left {
          .disp-non {
            display: none;
          }
        }
        .unit-area {
          width: 628px;
          float: left;
          margin-left: 40px;
          .schedule-apply {
            float: left;
          }
          .schedule-permit {
            float: left;
          }
          .request-area {
            float: left;
            margin: 0 20px 0 10px;
            .no-request {
              line-height: 2.8;
            }
          }
          .approval-area {
            float: left;
            margin: 0 0 0 10px;
            .no-approval {
              line-height: 2.8;
            }
          }
        }
        .message-area {
          position: relative;
          float: right;
          height: 36px;
          width: 350px;
          .message {
            position: absolute;
            color: #c00;
            top: 0;
            right: 0;
            &.disp-non {
              display: none;
            }
          }
        }
      }
    }
    .tbl-top-area {
      max-width: 1680px;
    }
  }

  .update-date {
    font-size: 1.3rem;
    position: absolute;
    right: 5px;
    bottom: 0;
  }
}
.schedule-area {
  overflow: scroll;
}
.schedule-area-header {
  display: block;
}

.schedule-area-body {
  display: block;
  overflow-y: scroll;
}

.scheduled-date {
  @media all and (-ms-high-contrast: none) {
    span {
      vertical-align: top;
      margin-top: 7px;
    }
  }
}

.schedule-container {
  flex-grow: 1;
  padding: 15px 20px 10px;
  display: flex;
  flex-direction: column;
  .schedule-toolbar {
    margin-top: 10px;
    display: flex;
  }
  .schedule-table-area {
    position: relative;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    .loading {
      position: absolute;
      width: 100%;
      height: 100%;
    }
  }
  .schedule-table-box {
    flex-grow: 1;
    display: flex;
  }
}

.schedule-table-container {
  .description {
    width: calc(100% - 15px);
    height: 25px;
    padding: 5px;
    color: white;
    background-color: #434343;
    text-align: start;
  }

  .header-row {
    height: 80px;
    display: flex;

    .last-column {
      border-right: 1px solid #cccccc;
    }

    .editable:hover {
      &:before {
        content: "";
        position: absolute;
        bottom: 3px;
        background-image: url("../img/icon-edit-black.svg");
        background-repeat: no-repeat;
        display: inline-block;
        height: 14px;
        width: 14px;
        transition: all 0.15s ease-in-out;
        z-index: 1;
      }
      &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 2px solid #c1d8f0;
        cursor: pointer;
      }
    }

    > div {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 5px;
      background-color: #cccccc;
      border-right: 1px solid #ffffff;
      border-bottom: 1px solid #eff0f3;
      color: #000000;
      font-size: 1.2rem;
      text-align: center;
    }

    .scroll-margin {
      width: 15px;
      min-width: 15px;
      background-color: transparent;
      border: none;
    }

    &.fixed-row {
      overflow-y: scroll;
    }

    &.fixed-row::-webkit-scrollbar {
      display: none;
    }

    .spanning-header {
      display: flex;
      flex-direction: column;
      padding: 0;
      background-color: transparent;
      border: none;

      .top-row {
        height: 27px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 5px;
        background-color: #cccccc;
        border-right: 1px solid #ffffff;
        border-bottom: 1px solid #eff0f3;
      }

      .bottom-row {
        display: flex;
        height: 100%;

        > div {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 5px;
          background-color: #cccccc;
          border-right: 1px solid #ffffff;
          border-bottom: 1px solid #eff0f3;
        }
      }
    }
  }

  .body-area {
    height: 0;
    flex-grow: 1;
    overflow-y: scroll;

    &.fixed-area::-webkit-scrollbar {
      display: none;
    }

    .body-row {
      height: 80px;
      display: flex;
      background-color: #ffffff;

      .grid-cell {
        display: flex;
        justify-content: start;
        align-items: center;
        padding: 5px;
        background-color: #ffffff;
        border-right: 1px solid #e4e4e4;
        border-bottom: 1px solid #cccccc;
        overflow: hidden;
        white-space: pre-wrap;
        &.break-word {
          line-break: anywhere;
        }
        &.editable:hover {
          &:before {
            content: "";
            position: absolute;
            bottom: 3px;
            right: 3px;
            background-image: url("../img/icon-edit-black.svg");
            background-repeat: no-repeat;
            display: inline-block;
            height: 14px;
            width: 14px;
            transition: all 0.15s ease-in-out;
          }
          &:after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border: 2px solid #c1d8f0;
            cursor: pointer;
          }
        }
        &.continuous-editable:hover {
          &:after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border: 2px solid #c1d8f0;
          }
        }
        &.center {
          justify-content: center;
          text-align: center;
        }
      }
      .note-editor {
        border-right: 1px solid #e4e4e4;
        border-bottom: 1px solid #cccccc;
      }
    }

    .scroll-margin {
      height: 15px;
      min-height: 15px;
      background-color: transparent;
      border: none;
    }
  }

  .result-list {
    height: calc(100% - 25px);
    display: flex;
    flex-direction: column;

    div {
      &.col1 {
        min-width: 100px;
        width: 100px;
      }

      &.col2 {
        min-width: 100px;
        width: 100px;
      }

      &.col3 {
        min-width: 150px;
        width: 150px;
      }

      &.col4 {
        min-width: 35px;
        width: 35px;
      }

      &.col5 {
        min-width: 35px;
        width: 35px;
      }
    }
  }

  .plan-list-container {
    height: calc(100% - 55px);
    display: flex;
  }
  .plan-list {
    position: relative;
    display: flex;
    flex-direction: column;
    &.plan-list-right {
      flex-grow: 1;
      overflow-y: hidden;
    }
    textarea {
      width: 100%;
      height: 100%;
      overflow-y: scroll;
      &::-webkit-scrollbar {
        display: none;
      }
    }
    div {
      position: relative;
      &.scroll-header {
        position: absolute;
        top: 0;
        right: 0;
        width: 15px;
        height: 80px;
        background-color: #eff0f3;
      }

      &.col1 {
        min-width: 100px;
        width: 100px;
      }

      &.col2 {
        min-width: 100px;
        width: 100px;
      }

      &.col3 {
        min-width: 150px;
        width: 150px;
      }

      &.col4 {
        min-width: 80px;
        width: 80px;
      }

      &.col5 {
        min-width: 120px;
        width: 120px;
      }

      &.col6 {
        min-width: 35px;
        width: 35px;
      }

      &.col7 {
        min-width: 35px;
        width: 35px;
      }

      &.col8 {
        min-width: 35px;
        width: 35px;
      }

      &.col9 {
        min-width: 120px;
        width: 120px;
      }

      &.col10 {
        min-width: 35px;
        width: 35px;
      }

      &.col11 {
        min-width: 120px;
        width: 120px;
      }

      &.col12 {
        min-width: 35px;
        width: 35px;
      }

      &.col13 {
        min-width: 70px;
        width: 70px;
      }

      &.col14 {
        min-width: 70px;
        width: 70px;
      }

      &.col15 {
        min-width: 70px;
        width: 70px;
      }

      &.col16 {
        min-width: 90px;
        width: 90px;
      }

      &.col17 {
        min-width: 160px;
        width: 160px;
      }

      &.col18 {
        min-width: 80px;
        width: 80px;
      }

      &.col19 {
        min-width: 80px;
        width: 80px;
      }

      &.col20 {
        min-width: 80px;
        width: 80px;
      }

      &.col21 {
        min-width: 120px;
        width: 120px;
      }

      &.col22 {
        min-width: 80px;
        width: 80px;
      }

      &.col23 {
        min-width: 120px;
        width: 120px;
      }

      span.label-red {
        position: absolute;
        width: 100%;
        bottom: 0;
        left: 0;
        height: 6px;
        background-color: #dd4477;
      }

      span.label-green {
        position: absolute;
        width: 100%;
        bottom: 0;
        left: 0;
        height: 6px;
        background-color: #66bb77;
      }
    }

    .continuous-update-loading {
      position: relative;
      width: 100%;
      height: 100%;

      &::after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        z-index: 1100;
        width: 30px;
        height: 30px;
        border-radius: 15px;
        border: 2px solid #ffffff;
        border-right-color: transparent;
        animation: spin-transparent 1s infinite linear;
        -webkit-animation: spin-transparent 1s infinite linear;
        -moz-animation: spin-transparent 1s infinite linear;
      }
    }
  }

  .paging-footer {
    height: 30px;
    width: calc(100% - 15px);
    color: #ffffff;
    background-color: #252c36;
    display: flex;
    justify-content: center;
    align-items: center;
    .pager {
      width: 40px;
      text-decoration: underline;
      cursor: pointer;
    }
    .page {
      text-align: center;
      width: 200px;
    }
    .pagination {
      position: relative;
    }
  }
}

.grid-container-schedule {
  grid-template-rows: 113px 1fr;
  -ms-grid-rows: 113px 1fr;
  grid-template-columns: 1125px 1fr;
  -ms-grid-columns: 1125px 1fr;
  &.chita {
    grid-template-columns: 848px 1fr;
  }
  &.chiba {
    grid-template-columns: max-content;
    .schedule {
      .pos-rel {
        position: relative;
      }
    }
    .color-gray {
      background-color: #f1f1f1;
    }
    .color-white {
      background-color: #fff;
    }
  }
  height: calc(100vh - 387px);
  .grid-left-header {
    tr {
      height: 113px;
    }
    th {
      &.topBtn {
        padding-top: 27px;
      }
      background-color: transparent;
    }
  }
  .grid-left-header-chiba {
    th {
      &.col-top {
        height: 27px;
      }
      &.col-bottom {
        height: 86px;
      }
      &.topBtn {
        padding-top: 27px;
      }
      background-color: transparent;
    }
  }

  .grid-right-header {
    th {
      &.col-top {
        height: 27px;
      }
      &.col-bottom {
        height: 86px;
      }
      background-color: transparent;
    }
    .header-height {
      height: 44px;
    }
    .chita-list-header-right-3rd {
      th {
        height: 43px;
      }
    }
  }
  &.is-wide-table {
    grid-template-columns: 406px 1fr;
    .grid-left-header {
      th:nth-child(3),
      th:nth-child(4),
      th:nth-child(5),
      th:nth-child(8),
      th:nth-child(9),
      th:nth-child(10),
      th:nth-child(11),
      th:nth-child(12),
      th:nth-child(13),
      th:nth-child(14) {
        display: none;
      }
    }
    .grid-left-body {
      td:nth-child(3),
      td:nth-child(4),
      td:nth-child(5),
      td:nth-child(8),
      td:nth-child(9),
      td:nth-child(10),
      td:nth-child(11),
      td:nth-child(12),
      td:nth-child(13),
      td:nth-child(14) {
        display: none;
      }
    }
  }
  &.is-wide-table-chiba {
    .grid-right-header {
      th.close {
        display: none;
      }
    }
    .grid-right-body {
      td.close {
        display: none;
      }
    }
  }
  .schedule-table {
    width: initial;
  }
}
.list-table-row {
  thead.schedule {
    background-color: #ccc;
  }
  .schedule {
    td {
      height: 80px;
      position: relative;
      &.txt-center {
        text-align: center;
        padding: 0;
      }

      &.folded {
        width: 8px;
        min-width: 0;
        padding: 0;
      }
    }
    .schedule-right-th {
      position: relative;
      .icon-edit {
        position: absolute;
        bottom: 5px;
        left: 50%;
        transform: translateX(-50%);
        -webkit-transform: translateX(-50%);
      }
    }
    th.header-bottom:hover {
      padding: 0;
      cursor: pointer;
      .title {
        display: none;
      }
      .default {
        cursor: default;
      }
    }
    th.header-2row:hover {
      padding: 0;
      cursor: pointer;
      .title {
        display: none;
      }
      .default {
        cursor: default;
      }
    }
    .icon-edit-history {
      top: 5px;
      right: -4px;
      position: absolute;
      transform: translateX(-50%);
      -webkit-transform: translateX(-50%);
    }
    .schedule-right-body {
      position: relative;
      .icon-edit {
        bottom: 5px;
        left: 50%;
      }
      td.no-hover::after {
        display: none;
      }
    }
    th {
      position: relative;

      &.folded {
        width: 8px;
        min-width: 0;
        padding-right: 0;
        padding-left: 0;

        &--top {
          border-bottom: none;
        }
      }

      &:last-child {
        &.folded {
          border-right: #fff;
        }
      }
    }
    th,
    td {
      &.col1 {
        max-width: 60px;
        min-width: 60px;
        width: 60px;
        &.pos-rel {
          position: relative;
        }
        display: none;
      }
      &.col2 {
        max-width: 80px;
        min-width: 80px;
        width: 80px;
        &.pos-rel {
          position: relative;
        }
      }
      &.col3 {
        max-width: 80px;
        min-width: 80px;
        width: 80px;
      }
      &.col-worktime-top {
        max-width: 100px;
        min-width: 100px;
        width: 100px;
      }
      &.col-worktime {
        max-width: 50px;
        min-width: 50px;
        width: 50px;
      }
      &.col4 {
        max-width: 80px;
        min-width: 80px;
        width: 80px;
      }
      &.col5,
      .col6,
      .col7,
      .col8 {
        max-width: 60px;
        min-width: 60px;
        width: 60px;
      }
      &.col9 {
        max-width: 100px;
        min-width: 100px;
        width: 100px;
      }
      &.col10 {
        max-width: 160px;
        min-width: 160px;
        width: 160px;
      }
      &.col11 {
        max-width: 60px;
        min-width: 60px;
        width: 60px;
      }
      &.col12 {
        max-width: 40px;
        min-width: 40px;
        width: 40px;
      }
      &.col13 {
        max-width: 40px;
        min-width: 40px;
        width: 40px;
      }
      &.col14 {
        max-width: 40px;
        min-width: 40px;
        width: 40px;
      }
      &.col15 {
        max-width: 40px;
        min-width: 40px;
        width: 40px;
      }
      &.col16 {
        max-width: 60px;
        min-width: 60px;
        width: 60px;
      }
      &.col17 {
        max-width: 40px;
        min-width: 40px;
        width: 40px;
      }
      &.col18 {
        max-width: 120px;
        min-width: 120px;
        width: 120px;
      }
      &.col19 {
        max-width: 140px;
        min-width: 140px;
        width: 140px;
      }
      &.col11_gas {
        max-width: 60px;
        min-width: 60px;
        width: 60px;
      }
      &.col_check {
        max-width: 60px;
        min-width: 60px;
        width: 60px;
      }
      &.col_chita {
        max-width: 68px;
        min-width: 68px;
        width: 68px;
      }
      &.col_comment {
        max-width: 70px;
        min-width: 70px;
        width: 70px;
      }
      &.col_index {
        max-width: 30px;
        min-width: 30px;
        width: 30px;
      }
    }
  }
}
.schedule-update {
  padding: 20px 60px;
  width: 400px;
  .updating-msg {
    text-align: center;
    p {
      margin-bottom: 5px;
    }
  }
  .loading-area {
    height: 42px;
    margin-top: 20px;
  }
}

.schedule-update-chiba {
  padding: 20px 60px;
  width: 400px;
  &.isSkipped {
    width: 480px;
  }
  .updating-msg {
    text-align: center;
    p {
      margin-bottom: 5px;
    }
  }
  .loading-area {
    height: 42px;
    margin-top: 20px;
  }
}

.permit-table-download {
  padding: 20px 60px;
  width: 400px;
  .updating-msg {
    text-align: center;
    p {
      margin-bottom: 5px;
    }
  }
  .loading-area {
    height: 42px;
    margin-top: 20px;
  }
}

.schedule-request {
  .request-info {
    margin-bottom: 20px;
  }
}
.schedule-approve {
  .approve-info {
    margin-bottom: 20px;
  }
}

.loading-footer-download {
  position: relative;

  &::after {
    content: "";
    margin: auto;
    z-index: 1100;
    width: 20px;
    height: 20px;
    border-radius: 20px;
    border: 3px solid #0866c6;
    border-right-color: transparent;
    animation: spin-transparent 1s infinite linear;
    -webkit-animation: spin-transparent 1s infinite linear;
    -moz-animation: spin-transparent 1s infinite linear;
  }
}

.holiday-container {
  flex-grow: 1;
  display: flex;
  .holiday-table {
    display: flex;
    flex-direction: column;
    .row {
      display: flex;
      > div {
        display: flex;
        align-items: flex-start;
      }
      &.summary {
        width: fit-content;
        > div {
          width: 100px;
        }
      }
    }
    .header-row {
      font-size: 1.2rem;
      height: 30px;
      background-color: #ccc;
      > div:first-child {
        border-left: 1px solid #e4e4e4;
      }
      > div:last-child {
        border-right: 1px solid #e4e4e4;
      }
      > div {
        align-items: center;
        justify-content: center;
        border-left: solid 1px #fff;
      }
    }
    .table-body {
      font-size: 1.1rem;
      height: 0;
      flex-grow: 1;
      overflow-y: scroll;
      &.summary {
        -ms-overflow-style: none;
        scrollbar-width: none;
        &::-webkit-scrollbar {
          display: none;
        }
      }
    }
    .body-row {
      background-color: #fff;
      border-bottom: 1px solid #ccc;
      transition: all 0.5s;
      position: relative;
      > div:last-child {
        border-right: 1px solid #e4e4e4;
      }
      > div {
        border-left: solid 1px #e4e4e4;
      }
      .data {
        height: 60px;
        width: 100%;
        display: flex;
        align-items: center;
        padding: 5px;
        overflow: hidden;
      }
    }
  }
  .holiday-datepicker {
    .react-datepicker {
      border: none;
      margin-left: -10px;
      .react-datepicker__month-container {
        border: 1px solid #aeaeae;
        border-radius: 0.3rem;
        margin-left: 10px;
      }
      .react-datepicker__day--selected {
        border-radius: 1.2rem;
        background-color: #eda2d9;
        color: #000;
      }
      .react-datepicker__day--keyboard-selected {
        background-color: transparent;
        &:hover {
          background-color: #f0f0f0;
        }
      }
      .react-datepicker__day--outside-month {
        visibility: hidden;
      }
    }
    &.disable-nav-next {
      .react-datepicker__navigation--next {
        display: none;
      }
    }
  }
  .holiday-calendar-paging {
    color: #7f7f7f;
    display: inline-block;
    font-size: 40px;
    transform: rotate(90deg);
    &:hover {
      color: #333333;
      cursor: pointer;
    }
  }
}

.clipboard-dialog {
  padding: 10px 30px;
  width: 400px;
  .clipboard-item {
    width: 100%;
    margin: 8px 0;
    display: flex;
    .clipboard-link {
      flex-grow: 1;
      background-color: #fff;
      padding: 0 5px;
      border: none;
      border-radius: 5px;
      min-height: 32px;
      display: flex;
      align-items: center;
    }
  }
}

.paste-area {
  text-align: center;
  border: 3px dashed #aaa;
  border-radius: 5px;
  padding: 15px;
  background-color: #ebf3fb;
}

/*tooltip
----------------------------------*/
.schedule-tip {
  &.tooltip {
    font-family: inherit !important;
    max-width: 200px;

    &.in {
      opacity: 1 !important;
      filter: alpha(opacity=100) !important;
    }
  }

  .tooltip-inner {
    padding: 10px !important;
    max-width: none !important;
    color: $c-text !important;
    background-color: #fff !important;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2), 0 1px 2px rgba(0, 0, 0, 0.2);
    .form-row:last-child {
      margin-bottom: 0;
    }
    .wrap {
      white-space: pre-wrap;
    }
  }
}

.schedule-tooltip {
  position: absolute;
}

/* Revision history
----------------------------------*/
.schedule-revision-history {
  display: flex;
  flex-direction: column;
  gap: 10px;
  text-align: left;

  &__item {
    background: #fff;
    border: 1px solid rgba(0, 0, 0, 0.15);
    padding: 8px;
  }

  &__title {
    font-weight: bold;
    margin-bottom: 16px;
  }

  &__meta {
    @include font-size(1.2);
    display: flex;
    gap: 8px;
    color: $c-text-light-navy;
  }
}
