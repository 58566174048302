#progressNotifications {
  z-index: 1001;
  position: fixed;
  top: 65px;
  bottom: 0;
  right: 0;
  width: 350px;
  transition: transform 0.2s linear;
  transform: translateX(100%);
  background-color: rgba(86, 86, 86, 0.7);
  background-color: #ffffff;
  color: #000000;
  overflow-y: auto;

  // NOTE(joshua-hashimoto): ドロワーの開閉用
  &.open {
    transform: translateX(0);
    box-shadow: -2px 0 10px 0 rgba(4, 0, 0, 0.23);
  }

  .pill {
    border-radius: 17px;
    line-height: 1;
    padding: 6px 6px;
    background: #dddddd;
    color: #666666;
    position: relative;
    font-size: 12px;
    display: flex;
    align-items: center;
    cursor: pointer;

    &.badge:after {
      content: "";
      position: absolute;
      background: #cc0000;
      height: 14px;
      width: 14px;
      top: -4px;
      right: -4px;
      text-align: center;
      border-radius: 50%;
    }
  }

  .is-pill-selected {
    background: $c-blue;
    color: #fff;
  }
  .sticky-header {
    position: sticky;
    z-index: 100;
    top: 0;
  }

  #drawer-header {
    display: flex;
    justify-content: space-between;
    background-color: #cccccc;
    padding: 0 0.5rem;
    align-items: center;
    line-height: 45px;

    #filter-button {
      cursor: pointer;
      width: 20px;
      display: flex;
      align-items: center;
      margin-left: 7px;
    }

    #title-area {
      display: flex;
      justify-content: center;

      #title-text {
        font-size: 1.4rem;
        margin-right: 5px;
      }
      #refresh-icon {
        width: 23px;
        display: flex;
        align-items: center;
        cursor: pointer;
      }
    }

    #close-button {
      margin-right: 5px;
      width: 15px;
      display: flex;
      align-items: center;
      cursor: pointer;
    }
  }

  #list-header {
    display: flex;
    justify-content: space-between;
    background: #fff;
    padding: 8px 0.5rem;
    align-items: center;
    border-bottom: 1px solid #999;

    #pills {
      display: flex;
      gap: 1rem;

      #complete-report-pill {
      }

      #cancel-report-pill {
      }
    }

    #read-all-btn {
    }
  }

  #progress-notification-list {
    height: 100%;
  }

  .progress-notification {
    background: #fff;
    padding: 1rem 1.2rem;
    border-bottom: 1px solid #cccccc;
    cursor: default;
    font-weight: 400;
    font-weight: bold;

    &.is-new {
      background: #fffad8;
    }

    &.is-read {
      background: #eff0f3;
    }

    .progress-notification-header {
      display: flex;
      justify-content: space-between;

      .progress-notification-title {
      }

      .progress-notification-timestamp {
        line-height: 2;
      }
    }

    .progress-notification-content {
      padding-bottom: 5px;
      .progress-notification-detail-row {
        display: flex;
        line-height: 1.4;

        .progress-notification-detail-row-label {
          min-width: 10rem;
          text-align: right;
        }

        .progress-notification-detail-row-content {
          word-break: break-all;
        }
      }
    }

    .progress-notification-footer {
      width: 100%;
      text-align: right;

      .progress-notification-detail-btn {
        cursor: pointer;
        background: $c-blue;
        color: #fff;
        border: none;
        border-radius: 3px;
        line-height: 100%;
        width: 60px;
        height: 24px;
        font-weight: 700;
      }
    }
  }
}

#progress-notification-search-modal {
  font-weight: bold;
}

#progress-notification-detail-modal {
  display: flex;
  font-weight: bold;
  width: 800px;

  .column {
    flex-basis: 50%;
  }
  .pl-60 {
    padding-left: 60px;
  }
  .pr-30 {
    padding-right: 30px;
  }
  .row {
    display: flex;
    margin-bottom: 15px;
    .label {
      flex-basis: 20%;
      text-align: right;
      margin-right: 16px;
    }
    .value {
      flex-basis: 70%;
    }
    .comments {
      flex-basis: 80%;
      max-height: 300px;
      border-top: 1px solid #ccc;
      border-bottom: 1px solid #ccc;
    }
    .icon-link {
      cursor: pointer;
      width: 15px;
      display: inline-block;
      margin-left: 15px;
      vertical-align: text-bottom;
    }
  }
  .file-list {
    max-height: 300px;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
  }
}

.pointer-cursor {
  cursor: pointer !important;
}

.subject {
  font-size: 1.2em;
  margin-left: 5px;
  line-height: 2;
}

#progress-notification-bell {
  width: 50px;
  height: 45px;
  position: relative;
  transition: all 0.15s ease-in-out;
  font-size: 1.8rem;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    cursor: pointer;
    background-color: $c-blue;
  }

  > .label-badge {
    background: #cc0000;
    color: white;
    font-size: 8px;
    position: absolute;
    top: 5px;
    right: 4px;
    border-radius: 50%;
    border: 1px solid #ffc79e;
    height: 20px;
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
