@charset "utf-8";

// for tailwind
@tailwind base;
@tailwind components;
@tailwind utilities;

// styles
@import "reset";
@import "variables";
@import "fonts";
@import "common";
@import "header";
@import "login";
@import "matrix";
@import "grid";
@import "cursor";
@import "toast";
@import "toggle_with_words";
@import "list";
@import "animation";
@import "qr";
@import "_notifications";
@import "layout_notifications";
@import "sxlayout";
