@font-face {
  font-family: "sxwebfont";
  src: url("../fonts/sxwebfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

.status-mark {
  font-family: "sxwebfont";
}

[class^="icon-"],
[class*=" icon-"] {
  speak: none;
  font-style: normal;
  font-weight: bold;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon:before {
  display: inline-block;
  height: 16px;
  width: 16px;
}

.icon-add_circle:before {
  content: url("../img/icon-add-blue.svg");
  width: 24px;
  height: 24px;
}
.icon-add_circle_disabled:before {
  content: url("../img/icon-add-black.svg");
  opacity: 0.5;
  width: 24px;
  height: 24px;
}
.icon-forward:before {
  content: url("../img/icon-forward-black.svg");
}
.icon-attach_file:before {
  content: "";
  background-image: url("../img/icon-clip-black.svg");
  background-repeat: no-repeat;
  display: inline-block;
  height: 16px;
  width: 16px;
}
.icon-mode_edit:before {
  content: "";
  background-image: url("../img/icon-edit-black.svg");
  background-repeat: no-repeat;
  display: inline-block;
  height: 14px;
  width: 14px;
}
.icon-file_upload:before {
  content: url("../img/icon-upload-white.svg");
}
.icon-keyboard_arrow_up:before {
  content: url("../img/icon-up-black.svg");
}
.icon-check:before {
  content: url("../img/icon-ckeck-white.svg");
}
.icon-close:before {
  content: url("../img/icon-close-black.svg");
}
.icon-cached:before {
  content: url("../img/icon-reload-black.svg");
  width: 24px;
  height: 24px;
}
.icon-delete {
  &:before {
    content: url("../img/icon-delete-black.svg");
    opacity: 0.3;
    width: 14px;
  }
  &:hover::before {
    opacity: 0.9;
  }
}
.icon-get_app:before {
  content: url("../img/icon-download-white.svg");
}
.icon-search:before {
  content: url("../img/icon-search-white.svg");
}
.icon-thumb_up:before {
  content: url("../img/icon-thumb-up-black.svg");
}
.icon-visibility_off:before {
  content: url("../img/icon-schedule-off-black.svg");
}
.icon-help:before {
  content: url("../img/icon-help-black.svg");
  width: 24px;
  height: 24px;
}
.icon-help_outline:before {
  content: url("../img/icon-help-outline.svg");
}
.icon-check_box:before {
  content: url("../img/icon-ckeckbox-white.svg");
  width: 18px;
  height: 18px;
}
.icon-check_box_disabled:before {
  content: url("../img/icon-ckeckbox-gray.svg");
  width: 18px;
  height: 18px;
}
.icon-submenu:before {
  content: url("../img/icon-submenu-gray.svg");
  display: inline-block;
  height: 18px;
  width: 4.5px;
}
.icon-history:before {
  content: url("../img/icon-history-black.svg");
  display: inline-block;
  height: 18px;
  width: 18px;
}
.icon-lock:before {
  // TODO 動作確認用のアイコン
  content: url("../img/icon-lock-gray.svg");
}
.icon-key:before {
  // TODO 動作確認用のアイコン
  content: url("../img/icon-key-gray.svg");
}
