.grid {
  /*
   * Matrix Base Layout
   **/
  &-container {
    display: grid;
    position: relative;
    z-index: 0;
  }

  &-left-header {
    grid-column: 1;
    grid-row: 1;
    overflow: hidden;
    margin-right: 5px;
  }

  &-left-header-chiba {
    grid-column: 1;
    grid-row: 1;
    overflow: hidden;
    margin-right: 5px;
  }

  &-left-body {
    grid-column: 1;
    grid-row: 2;
    overflow-x: hidden;
    overflow-y: hidden;
    margin-right: 5px;
    padding-bottom: 1px; // for IE bugfix.

    &::-webkit-scrollbar {
      visibility: hidden;
    }
  }

  &-right-header {
    grid-column: 2;
    grid-row: 1;
    overflow: hidden;
  }

  &-right-body {
    grid-column: 2;
    grid-row: 2;
    overflow: scroll;
    padding-bottom: 1px; // for IE bugfix.
    &.sch-chita {
      overflow-x: hidden;
    }
  }

  /*
   * Table Common
   **/
  &-table {
    background-color: #fff;
    border-collapse: collapse;
    table-layout: fixed;
  }

  &-table th,
  &-table td {
    box-sizing: border-box;
    padding: 0;
    text-align: center;
    vertical-align: middle;
  }
  &-table thead {
    background-color: #ccc;
  }
  &-table th {
    position: relative;
    @include font-size(1.2);
    border: solid 1px #fff;
    border-top: solid 1px #ccc;
    &:first-child {
      border-left: solid 1px #e4e4e4;
    }
    &:last-child {
      border-right: solid 1px #e4e4e4;
    }
  }

  &-table-body td {
    @include font-size(1.1);
    border: solid 1px #e4e4e4;
    border-bottom: solid 1px #ccc;
  }

  &-icon {
    vertical-align: middle;
    border: none;
    background: transparent;
    @include font-size(1.6);
  }

  /*
   * Right Table
   **/
  &-table-right th:first-child,
  &-table-right td:first-child {
    border-left: none;
  }

  &-table-right-header th {
    padding: 0 5px;
    &.grid-process-major-classname {
      &.process-major-bgc {
        background-color: #8c8c8c;
      }
    }
  }

  &-table-right-body tr:first-child td {
    border-top: none;
  }

  &-table-right-body tr.is-current {
    background: rgba(0, 0, 0, 0.05);
  }

  &-table-right-body td .grid-cell-wrap {
    display: block;
    width: 100%;
    height: 100%;
    position: relative;
  }
  &-table-right-body td .grid-cell-con {
    display: table;
    width: 100%;
    height: 100%;
  }
  &-table-right-body td .grid-cell-inner {
    position: relative;
    display: table-cell;
    width: 100%;
    max-width: 80px;
    height: 100%;
    vertical-align: middle;
    // z-index: 100;
    &.schedule-note {
      word-break: break-all;
    }
  }
  &-table-right-body td:hover {
    .grid-cell-wrap {
      &:after {
        content: " ";
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
        border: 2px solid #c1d8f0;
      }
    }
  }
  &-table-right-body .grid-cell-processing {
    .grid-cell-inner::after {
      background: rgba(0, 0, 0, 0.5) url(../img/grid_spinner.svg) no-repeat center;
      content: "";
      display: block;
      height: 80px;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      // z-index: 100;
    }
  }
  &-table-right-body .grid-cell-error,
  &-table-right-body .grid-cell-error:hover {
    .grid-cell-wrap::after {
      content: " ";
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 100%;
      border: 2px solid #f00;
    }

    .grid-icon-edit {
      visibility: hidden;
    }
  }

  &-table-right-body .grid-cell-not-allowed {
    cursor: not-allowed;

    * {
      pointer-events: none;
    }
  }

  /** for schedule **/
  &-table-right-body-schedule tr:first-child td {
    border-top: none;
  }

  &-table-right-body-schedule tr.is-current {
    background: rgba(0, 0, 0, 0.05);
  }

  &-table-right-body-schedule td .grid-cell-wrap {
    display: block;
    width: 100%;
    height: 100%;
    position: relative;
  }
  &-table-right-body-schedule td .grid-cell-con {
    display: table;
    width: 100%;
    height: 100%;
  }
  &-table-right-body-schedule td .grid-cell-inner {
    position: relative;
    display: table-cell;
    width: 100%;
    height: 100%;
    vertical-align: middle;
    z-index: 100;
    &.schedule-note {
      word-break: break-all;
    }
  }
  &-table-right-body-schedule td:hover {
    .grid-cell-wrap {
      &:after {
        content: " ";
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
        border: 2px solid #c1d8f0;
      }
      &.not-editable {
        &:after {
          content: none;
        }
      }
    }
  }
  &-table-right-body-schedule .grid-cell-processing {
    .grid-cell-inner::after {
      background: rgba(0, 0, 0, 0.5) url(../img/grid_spinner.svg) no-repeat center;
      content: "";
      display: block;
      height: 80px;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 100;
    }
  }
  &-table-right-body-schedule .grid-cell-error,
  &-table-right-body-schedule .grid-cell-error:hover {
    .grid-cell-wrap::after {
      content: " ";
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 100%;
      border: 2px solid #f00;
    }

    .grid-icon-edit {
      visibility: hidden;
    }
  }

  &-table-right-body-schedule .grid-cell-not-allowed {
    cursor: not-allowed;

    * {
      pointer-events: none;
    }
  }

  /*
   * Left Table
   **/
  &-table-left-header {
    height: 100%;
    &th {
      padding: 0;
    }
  }

  &-table-left-body tr:first-child td {
    border-top: none;
  }

  &-table-left-body tr.is-current {
    background: rgba(0, 0, 0, 0.05);
  }

  &-table-left-body td .grid-cell-wrap {
    display: block;
    width: 100%;
    height: 100%;
    max-height: 79px;
    overflow: hidden;
    position: relative;
  }
  &-table-left-body td .grid-cell-con {
    display: table;
    width: 100%;
    height: 100%;
  }
  &-table-left-body td .grid-cell-inner {
    position: relative;
    display: table-cell;
    width: 100%;
    height: 100%;
    vertical-align: middle;
    word-break: break-all;
    z-index: 100;
    &.wrap {
      white-space: pre-wrap;
    }
  }
  &-table-left-body td.grid-cell-clickable:hover {
    .grid-cell-wrap {
      &:after {
        content: " ";
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
        border: 2px solid #c1d8f0;
      }
    }
  }

  &-table-left-body td.no-hover::after {
    display: none;
  }

  &-table-left-body .react-contextmenu-wrapper {
    height: 100%;
  }

  /** for schedule **/
  &-table-left-body-schedule tr:first-child td {
    border-top: none;
  }

  &-table-left-body-schedule tr.is-current {
    background: rgba(0, 0, 0, 0.05);
  }

  &-table-left-body-schedule td .grid-cell-wrap {
    display: block;
    width: 100%;
    height: 100%;
    max-height: 79px;
    overflow: hidden;
    position: relative;
  }
  &-table-left-body-schedule td .grid-cell-con {
    display: table;
    width: 100%;
    height: 100%;
  }
  &-table-left-body-schedule td .grid-cell-inner {
    position: relative;
    display: table-cell;
    width: 100%;
    height: 100%;
    vertical-align: middle;
    word-break: break-all;
    z-index: 100;
    &.wrap {
      white-space: pre-wrap;
    }
  }
  &-table-left-body-schedule td.grid-cell-clickable:hover {
    .grid-cell-wrap {
      &:after {
        content: " ";
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
        border: 2px solid #c1d8f0;
      }
    }
  }

  &-table-left-body-schedule td.no-hover::after {
    display: none;
  }

  &-table-left-body-schedule .react-contextmenu-wrapper {
    height: 100%;
  }

  /*
   * Utility
   **/
  &-cell-vertical {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    margin: auto;
    width: 1em;
    word-break: break-all;
  }

  &-dragging-header {
    position: absolute;
    pointer-events: none;
    border-top: 3px solid #678fcf;
    border-left: 3px solid #678fcf;
    border-right: 3px solid #678fcf;
    background-color: rgba(43, 100, 191, 0.2); // #2b64bf
  }
  &-drop-header {
    position: absolute;
    pointer-events: none;
    width: 3px;
    background-color: #678fcf;
    &.disabled {
      background-color: transparent;
    }
    &:before {
      content: " ";
      position: absolute;
      top: 0;
      left: -9px;
      width: 20px;
      height: 3px;
      background-color: inherit;
    }
  }
  &-dragging-body {
    position: absolute;
    pointer-events: none;
    border-bottom: 3px solid #678fcf;
    border-left: 3px solid #678fcf;
    border-right: 3px solid #678fcf;
    background-color: rgba(43, 100, 191, 0.2); // #2b64bf
  }
  &-drop-body {
    position: absolute;
    pointer-events: none;
    width: 3px;
    background-color: #678fcf;
    &.disabled {
      background-color: transparent;
    }
    &:before {
      content: " ";
      position: absolute;
      bottom: 0;
      left: -9px;
      width: 20px;
      height: 3px;
      background-color: inherit;
    }
  }
  &-cell-clickable {
    cursor: pointer;
  }
  &-cell-gray {
    background-color: #f5f5f5;
  }
  &-cell-blue {
    background-color: #e2f2fe;
  }
  &-cell-yellow {
    background-color: #f5f657;
  }
  &-cell-orange {
    background-color: #ffa11e;
  }
  &-cell-green {
    background-color: #e1fae1;
  }

  &-textarea {
    border: none;
    box-sizing: border-box;
    display: block;
    height: 100%;
    resize: none;
    width: 100%;
  }

  &-context-menu {
    position: absolute;
    padding: 7px 0;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 3px;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    .menu-item {
      padding: 7px 15px;
      white-space: nowrap;
      &:hover {
        background-color: #eee;
      }
    }
  }

  /*
   * Progress Grid
   * (進捗管理マトリクス固有のスタイル)
   */
  &-container-progress {
    grid-template-rows: 193px 1fr;
    grid-template-columns: auto 1fr;
    height: calc(100vh - 154px);

    .grid-table-body td {
      height: 80px;
    }

    .grid-table-right th:not([colspan]),
    .grid-table-right td {
      max-width: 80px;
      min-width: 80px;
    }

    .grid-separator-bg {
      background-color: #e5e5e5;
    }
    .grid-separator {
      height: 22px;
      line-height: 1;
      border: solid 1px #fff;
      border-bottom: solid 1px #ccc !important;
      position: relative;
      &:first-child {
        border-left: solid 1px #e4e4e4;
      }
    }

    .grid-separator .react-contextmenu-wrapper {
      align-items: center;
      display: flex;
      height: 100%;
      justify-content: center;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }

    @media all and (-ms-high-contrast: none) {
      .grid-separator .react-contextmenu-wrapper {
        top: auto;
      }
    }

    .grid-process-middle-classname .grid-icon-cooperation {
      position: absolute;
      top: 5px;
      right: 5px;
      &:before {
        width: 10px;
        height: 10px;
      }
    }

    .grid-process-middle-classname:hover .grid-icon-cooperation {
      display: none;
    }

    .grid-icon-edit,
    .grid-icon-submenu-wrapper {
      transition: all 0.15s ease-in-out;
      z-index: 100;
      &:hover {
        cursor: pointer;
        color: #666;
      }
    }

    td .grid-icon-submenu-wrapper {
      visibility: hidden;
      position: absolute;
      width: 16px;
      height: 24px;
      top: 3px;
      right: 3px;
    }

    td:hover .grid-icon-submenu-wrapper {
      visibility: visible;
    }

    td .grid-icon-submenu {
      position: absolute;
      top: 2px;
      right: 4px;
    }

    th .grid-icon-edit {
      display: none;
    }
    th .grid-icon-submenu-wrapper {
      display: none;
      position: absolute;
      top: 0;
      right: 0;
      width: 18px;
      padding: 5px 0;
      background: radial-gradient(#ccc 40%, transparent);
    }
    th:hover .grid-icon-edit {
      display: inline-block;
    }
    th:hover .grid-icon-submenu-wrapper {
      display: inline-block;
    }
    .grid-process-middle-classname .grid-icon-edit {
      position: absolute;
      right: 6px;
      top: 6px;
    }

    .grid-process-name .process {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .grid-process-name .process .process-button {
      display: flex;
      justify-content: center;
    }

    td .grid-icon-edit {
      visibility: hidden;
      position: absolute;
      right: 3px;
      bottom: 3px;
    }

    td .grid-icon-edit-history {
      visibility: hidden;
      position: absolute;
      transition: all 0.15s ease-in-out;
    }

    td .react-contextmenu-wrapper {
      .grid-icon-edit {
        visibility: hidden;
        position: absolute;
        right: 3px;
        bottom: 3px;
        @supports (-ms-ime-align: auto) {
          top: 56px;
        }
      }
    }

    td:hover .grid-icon-edit,
    td:hover .grid-icon-edit-history {
      visibility: visible;
      z-index: 101; // 予定表(千葉)で隣り合う要素に100が設定されてしまっているため
    }

    td .grid-icon-information {
      position: absolute;
      right: 0px;
      @supports (-ms-ime-align: auto) {
        top: 0px;
      }
    }

    .grid-information {
      position: absolute;
      top: 2px;
      right: 2px;
      z-index: 100;
    }

    td .grid-icon-caution {
      font-size: 12px;
      position: absolute;
      right: 15px;
      top: 10px;
    }

    .grid-table-right-header tr:nth-child(1) th,
    .grid-table-right-header tr:nth-child(2) th,
    .grid-table-right-header tr:nth-child(4) th {
      height: 30px;
      max-width: 80px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .grid-table-right-header tr:nth-child(3) th {
      height: 80px;
      max-width: 80px;
      .process {
        overflow: hidden;
        height: 64px;
      }
    }

    .grid-cell-schedule {
      color: #666;
    }

    .grid-table-left-header tr:nth-child(1) th {
      height: 170px;
    }

    .col-no {
      max-width: 30px;
      min-width: 30px;
    }
    .col-delivery-flg {
      max-width: 30px;
      min-width: 30px;
    }
    .col-item-complete {
      max-width: 30px;
      min-width: 30px;
    }
    .col-totaltest {
      max-width: 30px;
      min-width: 30px;
    }
    .col-area {
      max-width: 60px;
      min-width: 60px;
    }
    .col-device {
      max-width: 50px;
      min-width: 50px;
    }
    .col-primary-charge {
      max-width: 50px;
      min-width: 50px;
    }
    .col-document-no {
      max-width: 60px;
      min-width: 60px;
    }
    .col-item {
      max-width: 120px;
      min-width: 120px;
    }
    .col-special-work {
      max-width: 110px;
      min-width: 110px;
    }
    .col-protection {
      max-width: 110px;
      min-width: 110px;
    }
    .col-regulation {
      max-width: 60px;
      min-width: 60px;
    }
    .col-systems {
      max-width: 50px;
      min-width: 50px;
    }
    .col-note {
      max-width: 115px;
      min-width: 115px;
    }
    .col-user {
      max-width: 60px;
      min-width: 60px;
      border-right: solid 1px #eff0f3;
    }

    .grid-table-left-body td.grid-cell-delivery .grid-btn-delivery {
      display: block;
      width: 16px;
      height: 16px;
      border-radius: 8px;
      border: 2px solid #f2f2f2;
      margin: 0 auto;
    }
    .grid-table-left-body td.grid-cell-delivery.is-off .grid-btn-delivery {
      opacity: 0;
      transition: all 0.25s ease-in-out;
    }
    .grid-table-left-body td.grid-cell-delivery.is-off:hover {
      &.grid-cell-clickable .grid-btn-delivery {
        opacity: 1;
        border: 2px solid #ccc;
        background-color: #fff;
      }
    }
    .grid-table-left-body td.grid-cell-delivery.is-on .grid-btn-delivery {
      border: 2px solid #ccc;
      background-color: $c-blue;
    }

    .grid-btn-collapse {
      &.chiba-close {
        left: 10px;
      }
      &.chiba-open {
        right: 10px;
      }
      box-sizing: border-box;
      left: 0;
      position: absolute;
      top: 5px;
      left: calc(50% - 10px);
      width: 18px;
      padding-right: 0;
      padding-left: 0;
      @include font-size(1);
      color: #313a47;
      border-radius: 3px;
      border: solid 1px #313a47;
      background-color: #fff;
      transition: all 0.15s ease-in-out;
      &:hover {
        cursor: pointer;
        color: #455265;
        border-color: #455265;
        background-color: #e5e5e5;
      }
    }

    .grid-btn-collapse-chiba {
      &.close {
        left: 10px;

        &.single {
          left: auto;
          right: 5px;
        }
      }
      &.open {
        right: 5px;
        &.disp-non {
          display: none;
        }
      }
      box-sizing: border-box;
      position: absolute;
      top: 5px;
      width: 18px;
      padding-right: 0;
      padding-left: 0;
      @include font-size(1);
      color: #313a47;
      border-radius: 3px;
      border: solid 1px #313a47;
      background-color: #fff;
      transition: all 0.15s ease-in-out;
      &:hover {
        cursor: pointer;
        color: #455265;
        border-color: #455265;
        background-color: #e5e5e5;
      }
    }

    .header-label {
      position: absolute;
      top: 10px;
      left: 10px;
      background-color: #fff;
      border: 2px solid #000;
      border-radius: 5px;
      padding: 2px 6px;
      white-space: nowrap;
      td {
        display: block;
        text-align: left;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }

  /*
   * Summary Grid
   * (工事サマリーマトリクス固有のスタイル)
   */
  &-container-summary {
    grid-template-rows: auto 1fr;
    grid-template-columns: auto 1fr;
    // height: calc(100vh - 300px);

    &.is-high {
      // height: calc(100vh - 165px);
    }

    .grid-table-left-header th,
    .grid-table-right-header th {
      height: 30px;
    }

    .grid-table-left-header th:nth-child(1),
    .grid-table-left-body td:nth-child(1) {
      max-width: 100px;
      min-width: 100px;
    }
    .grid-table-left-header th:nth-child(2),
    .grid-table-left-body td:nth-child(2) {
      max-width: 150px;
      min-width: 150px;
    }
    .grid-table-left-header th:nth-child(3),
    .grid-table-left-body td:nth-child(3) {
      max-width: 90px;
      min-width: 90px;
    }
    .grid-table-left-header th:nth-child(4),
    .grid-table-left-body td:nth-child(4) {
      max-width: 100px;
      min-width: 100px;
    }
    .grid-table-left-header th:nth-child(5),
    .grid-table-left-body td:nth-child(5) {
      max-width: 100px;
      min-width: 100px;
    }
    .grid-table-left-header th:nth-child(6),
    .grid-table-left-body td:nth-child(6) {
      max-width: 100px;
      min-width: 100px;
    }

    .grid-table-right-header th,
    .grid-table-right-body td {
      max-width: 120px;
      min-width: 120px;
    }

    .grid-table-left-body td,
    .grid-table-right-body td {
      height: 60px;
      max-height: 60px;
    }

    .grid-table-cell-item-num {
      display: block;
    }

    .grid-table-cell-progress-rate {
      display: block;
    }

    .grid-table-cell-delivery-flg {
      display: block;
    }
  }
}

.context-menu {
  @include font-size(1.2);
  position: absolute;
  padding: 7px 0;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 3px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  .menu-item {
    padding: 7px 15px;
    white-space: nowrap;
    text-align: center;
    &.disabled {
      color: #ccc;
      &:hover {
        background-color: inherit;
        cursor: inherit;
      }
    }
    &:hover {
      background-color: #eee;
      cursor: pointer;
    }
  }
}

html.win {
  .grid-left-body > table {
    margin-bottom: 17px;
  }
  .grid-right-header {
    margin-right: 17px;
  }
}

html.linux {
  .grid-left-body > table {
    margin-bottom: 17px;
  }
  .grid-right-header {
    margin-right: 17px;
  }
}

.en {
  .matrix {
    .rotate {
      transform: rotate(90deg);
      transform-origin: 100% 42%;
      white-space: nowrap;
    }
  }

  .schedule {
    .title,
    .title-disp {
      &.rotate {
        display: inline-block;
        position: absolute;
        transform: rotate(90deg);
        white-space: nowrap;
        &.left-prior {
          left: -20px;
        }
        &.left-new {
          left: -21px;
        }
        &.left-fire {
          left: -8px;
        }
        &.left-inside {
          left: -26px;
        }
        &.left-she {
          left: -24px;
        }
        &.left-air {
          left: 0px;
        }
        &.left-night {
          left: -15px;
        }
      }
    }
    .title:hover {
      display: none;
    }
  }
}

.ja {
  .grid-cell-narrow {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    margin: auto;
    width: 1em;
    word-break: break-all;
  }

  .schedule {
    .vertical {
      position: absolute;
      height: 80px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      @media all and (-ms-high-contrast: none) {
        transform: translate(-50%, -110%);
      }
      writing-mode: vertical-rl;
      text-orientation: upright;
    }
  }
  .vertical-text {
    writing-mode: vertical-rl;
  }
}
